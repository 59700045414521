define("ods-app/routes/area/subarea", ["exports", "servir-ember-utilities/routes/abstract-module-index", "ods-app/mixins/permission-validator"], function (_exports, _abstractModuleIndex, _permissionValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend(_permissionValidator.default, {
    routePermission: 'VIEW_SUBAREAS',
    breadcrumbsRoute: Ember.computed(function () {
      return [{
        name: 'Áreas',
        route: 'area'
      }, {
        name: 'Subáreas',
        route: 'area.subarea'
      }];
    }),

    model(params) {
      if (!params.area_id) {
        throw new Error('This route expects area_id parameter.');
      }

      return this.store.findRecord('area', params.area_id).then(area => ({
        area,
        subareas: Ember.A([])
      }));
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('reload', true);
    },

    renderTemplate: function () {
      this.render('area/subarea', {
        into: 'application'
      });
    }
  });

  _exports.default = _default;
});