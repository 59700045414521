define("ods-app/routes/candidato/record/planilla-cargo/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    modelName: 'proyecto-candidato-planilla-cargo',
    indexRoute: 'candidato.record.planilla-cargo',
    routeRecordIdentifierDynamicSegment: 'cargo_id',
    validations: Ember.computed(function () {
      return {
        proyectoCandidato: (0, _validators.validatePresence)({
          presence: true
        }),
        planillaCargo: (0, _validators.validatePresence)({
          presence: true
        }),
        monto: [(0, _validators.validatePresence)({
          presence: true
        }), (0, _validators.validateNumber)({
          gte: 0
        })]
      };
    }),
    relatedModelsToQuery: Ember.computed(function () {
      let candidato = this.modelFor('candidato.record').record;
      let relatedModels = [];
      relatedModels.push({
        name: 'cargosPlanilla',
        modelName: 'planilla-cargo'
      }, {
        name: 'currentCargosPlanilla',
        modelName: 'proyecto-candidato-planilla-cargo',
        queryParams: {
          filter: {
            'proyectoCandidato.candidato': candidato.get('id')
          },
          include: 'planillaCargo'
        }
      }, {
        name: 'proyectosCandidatos',
        modelName: 'proyecto-candidato',
        queryParams: {
          filter: {
            candidato: candidato.get('id')
          }
        }
      }, {
        name: 'proyectos',
        modelName: 'proyecto',
        queryParams: {
          filter: {
            'servicioAdquirido.tipoServicio': 'ADMINISTRACION_PLANILLA_FACTORH'
          },
          include: 'cliente,servicioAdquirido'
        }
      });
      return relatedModels;
    }),

    createRecordInstance() {
      const candidato = this.modelFor('candidato.record').record;
      const modelName = this.get('modelName');
      return this.store.createRecord(modelName, {
        candidato
      });
    },

    actions: {
      willTransition() {
        return true;
      }

    }
  });

  _exports.default = _default;
});