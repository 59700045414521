define("ods-app/controllers/requerimiento/record/ingreso/asociar-planilla", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    indexCtrl: Ember.inject.controller('requerimiento.record.ingreso'),
    ajaxService: Ember.inject.service(),
    indexRoute: 'requerimiento.record.ingreso',
    postSaveTransitionRoute: Ember.computed.readOnly('indexRoute'),
    usaMultiplesEmpresas: Ember.computed(function () {
      return this.currentUser.hasConfig('USA_MULTIPLES_EMPRESAS_FACTORH_POR_CLIENTE');
    }),

    resetProperties() {
      if (!this.changeset) {
        return;
      }

      Ember.setProperties(this.changeset, {
        factorhTipoPlanillaProxy: null,
        factorhTipoPlanilla: null,
        factorhTipoPlanillaStr: null,
        factorhDepartamentoProxy: null,
        factorhDepartamento: null,
        factorhDepartamentoStr: null,
        factorhPuestoProxy: null,
        factorhPuesto: null,
        factorhPuestoStr: null
      });
    },

    submit() {
      if (!this.changeset.get('factorhPuesto')) {
        this.toast.error('Debe seleccionar un puesto de FactoRH.');
        return;
      }

      if (!this.changeset.get('factorhTipoPlanilla')) {
        this.toast.error('Debe seleccionar un tipo de planilla de FactoRH.');
        return;
      } // if (!this.changeset.get('numeroSeguroSocial')) {
      //   this.toast.error('Debe poner el numero de seguro social.');
      //   return;
      // }


      return this._super(...arguments).then(() => {
        let aplicacion = this.model.aplicacionesPlaceHolder.firstObject;
        aplicacion.set('estatus', this.constants.APPLICATION_STATUS_CONTRATADO);
        aplicacion.save();
      });
    },

    actions: {
      discardChanges() {
        if (this.changeset.get('isDirty') || this.record.get('isNew')) {
          this.record.rollbackAttributes();
        }

        this.transitionToRoute(this.indexRoute);
      },

      onChangeEmpresa(factorhEmpresaObj) {
        this.resetProperties();

        if (!factorhEmpresaObj) {
          this.changeset.set('factorhEmpresa', null);
          Ember.setProperties(this.model, {
            factorhTiposPlanilla: Ember.A([]),
            factorhDepartamentos: Ember.A([]),
            factorhPuestos: Ember.A([])
          });
          return;
        }

        this.changeset.set('factorhEmpresa', factorhEmpresaObj.abreviatura);
        this.loader.setIsLoading();
        return Ember.RSVP.hash({
          factorhDepartamentos: this.ajaxService.request('/factorh-departamentos', {
            data: {
              empresaCodigo: factorhEmpresaObj.abreviatura
            }
          }).then(departamentosRes => departamentosRes.data),
          factorhTiposPlanilla: this.ajaxService.request('/factorh-tipos-planillas', {
            data: {
              empresaCodigo: factorhEmpresaObj.abreviatura
            }
          }).then(tiposPlanillaRes => tiposPlanillaRes.data),
          factorhPuestos: this.ajaxService.request('/factorh-puestos', {
            data: {
              empresaCodigo: factorhEmpresaObj.abreviatura
            }
          }).then(puestosRes => puestosRes.data)
        }).then(factorhData => {
          Ember.setProperties(this.model, factorhData);
        }).finally(() => this.loader.setNotLoading());
      },

      onChangeTipoPlanilla(factorhTipoPlanilla) {
        if (!factorhTipoPlanilla) {
          this.changeset.set('factorhTipoPlanilla', null);
          this.changeset.set('factorhTipoPlanillaStr', null);
          return;
        }

        this.changeset.set('factorhTipoPlanilla', factorhTipoPlanilla.codigo);
        this.changeset.set('factorhTipoPlanillaStr', factorhTipoPlanilla.nombre);
      },

      onChangeDepartamento(factorhDepartamento) {
        if (!factorhDepartamento) {
          this.changeset.set('factorhDepartamento', null);
          this.changeset.set('factorhDepartamentoStr', null);
          return;
        }

        this.changeset.set('factorhDepartamento', factorhDepartamento.abreviatura);
        this.changeset.set('factorhDepartamentoStr', factorhDepartamento.descripcion);
      },

      onChangePuesto(factorhPuesto) {
        if (!factorhPuesto) {
          this.changeset.set('factorhPuesto', null);
          this.changeset.set('factorhPuestoStr', null);
          return;
        }

        this.changeset.set('factorhPuesto', factorhPuesto.abreviatura);
        this.changeset.set('factorhPuestoStr', factorhPuesto.descripcion);
      }

    }
  });

  _exports.default = _default;
});