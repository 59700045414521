define("ods-app/controllers/proyecto/record/servicio/cancelar-proyecto", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    modelName: 'proyecto',
    indexRoute: 'proyecto.record.servicio',
    toast: Ember.inject.service(),
    loader: Ember.inject.service(),
    actions: {
      discardChanges() {
        this.changeset.rollback();
        this.record.rollbackAttributes();
        this.transitionToRoute(this.get('indexRoute'));
      },

      submitCancelacion() {
        if (!this.changeset.get('razonCancelacion.id')) {
          this.toast.error('Seleccione una razón de cancelación.');
          return;
        }

        this.loader.setIsLoading();
        this.changeset.set('estatus', this.constants.PROJECT_STATUS_CANCELED);
        this.changeset.save().then(() => {
          this.store.query('requerimiento', {
            filter: {
              proyecto: this.record.get('id')
            }
          }).then(requerimientos => {
            requerimientos.forEach(requerimiento => {
              requerimiento.set('estatus', 'CANCELADO');
              requerimiento.set('estaDeshabilitado', true);
              requerimiento.set('permiteAplicaciones', false);
              requerimiento.save();
            });
          }).catch(error => {
            let errorMsg = 'Ocurrió un error';

            if (error && error.message) {
              errorMsg = error.message;
            }

            this.toast.error(errorMsg);
          });
          this.toast.success('Proyecto cancelado.');
          this.transitionToRoute(this.get('indexRoute'));
        }).finally(() => this.loader.setNotLoading());
      }

    }
  });

  _exports.default = _default;
});