define("ods-app/routes/requerimiento-tipo-estatus/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset-validations/validators", "ods-app/mixins/permission-validator"], function (_exports, _abstractModuleRecord, _validators, _permissionValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_permissionValidator.default, {
    modelName: 'requerimiento-tipo-estatus',
    indexRoute: 'requerimiento-tipo-estatus',
    routePermission: 'VIEW_REQUIREMENT_TYPE_STATUS_COLLECTION',
    validations: Ember.computed(function () {
      return {
        nombre: (0, _validators.validatePresence)({
          presence: true
        }),
        fases: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    createRecordInstance() {
      return this.store.createRecord('requerimiento-tipo-estatus', {
        activo: 1
      });
    },

    actions: {
      willTransition() {
        return true;
      }

    }
  });

  _exports.default = _default;
});