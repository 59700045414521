define("ods-app/controllers/requerimiento/record", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    postSaveTransitionRoute: null,
    subRoutesFieldsMapping: Ember.computed(function () {
      return {
        informacion: ['codigo', 'proyecto', 'fechaSolicitud', 'estatus', 'reclutador', 'plaza', 'tipoPlaza', 'nombrePlaza', 'pais', 'departamento', 'municipio', 'nombreJefeInmediato', 'jornada', 'cantidadPlazas', 'supervisorOperativo', 'ubicacion', 'diasHorario', 'sustituye', 'esUrgente'],
        perfil: ['escolaridad', 'idiomas', 'programasComputacion', 'anosExperiencia', 'areasExperiencia', 'edad', 'genero', 'estadoCivil', 'religion', 'vehiculo', 'tipoLicencia', 'infoVehiculo', 'funcionesBasicas', 'competencias', 'responsabilidades', 'personalBajoCargo', 'presentacion'],
        'informacion-compensacion': ['moneda', 'salarioBase', 'bonificacionDecreto', 'montoHonorario', 'bonificacionIncenAdmin', 'bonificacionIncen', 'bonoTransporte', 'comisiones', 'comisionesEspecificas', 'gasolina'],
        aplicacion: ['fechaSeguimientoProgramada', 'fechaSeguimiento', 'responsable', 'horaContacto']
      };
    }),

    resetProperties() {
      this.setProperties({
        paises: [],
        departamentos: [],
        municipios: [],
        proyectos: [],
        usuarios: [],
        candidatos: [],
        aplicaciones: []
      });
    },

    postSave(record) {
      if (this.get('isNew')) {
        // TODO: Research about the best way to update the URL with record id.
        return this.transitionToRoute(this.get('routeName'), record.id);
      }
    },

    updateInformacionCompensacion(esExtranjero) {
      let changeset = this.changeset;
      let perfilPuesto = changeset.get('clientePerfilPuesto');
      let tipoPlaza = changeset.get('tipoPlaza');
      let rangoSalarial = perfilPuesto.get('rangoSalarial');

      if (!rangoSalarial) {
        return;
      }

      let salario = esExtranjero ? rangoSalarial.get('salarioMonedaExtranjera') : rangoSalarial.get('salarioMonedaLocal');
      let moneda = esExtranjero ? rangoSalarial.get('monedaExtranjera') : rangoSalarial.get('monedaLocal');
      let monedaUtilizar = esExtranjero ? this.constants.REQUERIMIENTO_MONEDA_RANGO_SALARIAL_EXTRANJERA : this.constants.REQUERIMIENTO_MONEDA_RANGO_SALARIAL_LOCAL;
      let propiedad = 'montoHonorario';
      let propiedadReset = 'salarioBase';
      let bonificacionDecreto = 0;
      let carga = 0;

      if (tipoPlaza === 'PLANILLA') {
        propiedad = 'salarioBase';
        propiedadReset = 'montoHonorario';
        carga = salario * this.constants.VALOR_COMPENSACION_CARGA;
        salario -= this.constants.VALOR_COMPENSACION_BONIFICACION;
        bonificacionDecreto = this.constants.VALOR_COMPENSACION_BONIFICACION;
      } else if (tipoPlaza === 'FACTURACION_INTERNA_14_MESES') {
        carga = salario * this.constants.VALOR_COMPENSACION_CARGA;
      } else {
        carga = salario;
      }

      changeset.set('moneda', moneda);
      changeset.set('cargaLaboral', parseInt(carga));
      changeset.set(propiedad, salario);
      changeset.set(propiedadReset, 0);
      changeset.set('bonificacionDecreto', bonificacionDecreto);
      changeset.set('monedaUtilizarRangoSalarial', monedaUtilizar);
    }

  });

  _exports.default = _default;
});