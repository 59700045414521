define("ods-app/controllers/requerimiento/record/ingreso", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    parentCtrl: Ember.inject.controller('requerimiento.record'),
    loader: Ember.inject.service(),
    datesCalculator: Ember.inject.service(),
    user: Ember.computed.readOnly('currentUser.user'),
    currentDate: null,
    asignaDepartamentoFactorhAProyecto: Ember.computed(function () {
      return this.currentUser.hasConfig('PROYECTO_ASIGNAR_FACTORH_DEPARTAMENTO');
    }),
    aplicacionesContratadasOCompletadas: Ember.computed('changeset.aplicaciones.@each.estatus', function () {
      return this.get('changeset.aplicaciones').filter(aplicacion => aplicacion.get('estatus') === this.constants.APPLICATION_STATUS_CONTRATADO || aplicacion.get('estatus') === this.constants.APPLICATION_STATUS_COMPLETADO);
    }),
    hasPlazasPendientesDeConfirmar: Ember.computed('changeset.{aplicaciones.@each.estatus,cantidadAplicaciones}', function () {
      let aplicacionesContratadasOCompletadas = this.get('changeset.aplicaciones').filter(aplicacion => aplicacion.get('estatus') === this.constants.APPLICATION_STATUS_CONTRATADO || aplicacion.get('estatus') === this.constants.APPLICATION_STATUS_COMPLETADO);
      return this.get('changeset.cantidadPlazas') > aplicacionesContratadasOCompletadas.length;
    }),
    hasPlazasPendientesDeSeleccionar: Ember.computed('changeset.{aplicaciones.@each.estatus,cantidadAplicaciones}', function () {
      let aplicacionesContratadas = this.get('changeset.aplicaciones').filter(aplicacion => aplicacion.get('estatus') === this.constants.APPLICATION_STATUS_CONTRATADO || aplicacion.get('estatus') === this.constants.APPLICATION_STATUS_COMPLETADO);
      return this.get('changeset.cantidadPlazas') > aplicacionesContratadas.length;
    }),
    cantidadDiasProceso: Ember.computed('changeset.{fechaFinalizacion,fechaSolicitud}', function () {
      if (!this.changeset.get('fechaSolicitud')) {
        return;
      }

      var comparacion = new Date();

      if (this.changeset.get('fechaFinalizacion')) {
        comparacion = this.get('changeset.fechaFinalizacion');
      }

      let diferencia = this.get('datesCalculator').getCantidadDiasHabiles(this.changeset.get('fechaSolicitud'), comparacion);
      return diferencia;
    }),
    actions: {
      seleccionarAplicacion(aplicacion, param2, status) {
        if (status) {
          aplicacion.set('seleccionado', true);
          aplicacion.set('estatus', this.constants.APPLICATION_STATUS_CONFIRMADO_PENDIENTE);
        } else {
          aplicacion.set('seleccionado', false);
          aplicacion.set('estatus', this.constants.APPLICATION_STATUS_EN_TERNA);
        }

        return aplicacion.save();
      },

      reactivarUltimaTerna(ternaActiva) {
        const aplicacionesTerna = ternaActiva.get('aplicaciones');
        const requerimiento = ternaActiva.get('requerimiento');
        const cantidadPlazas = requerimiento.get('cantidadPlazas');
        let contador = 0;
        aplicacionesTerna.forEach(aplicacion => {
          if (aplicacion.get('estatus') === this.constants.APPLICATION_STATUS_CONTRATADO || aplicacion.get('estatus') === this.constants.APPLICATION_STATUS_COMPLETADO) {
            contador += 1;
          }
        });

        if (contador === cantidadPlazas) {
          this.toast.error('No es posible reiniciar las selecciones.');
          return;
        }

        this.set('cantidadAplicaciones', contador);
        aplicacionesTerna.forEach(aplicacion => {
          if (aplicacion.seleccionado && aplicacion.get('estatus') !== this.constants.APPLICATION_STATUS_CONTRATADO && aplicacion.get('estatus') !== this.constants.APPLICATION_STATUS_COMPLETADO) {
            aplicacion.set('seleccionado', false);
            aplicacion.set('fechaIngreso', null);
            aplicacion.set('estatus', this.constants.APPLICATION_STATUS_EN_TERNA);
            aplicacion.save();
          }
        });
        ternaActiva.set('aceptada', false);
        return ternaActiva.save().then(() => {
          requerimiento.set('estatus', 'VACANTE');
          Ember.RSVP.resolve(requerimiento).then(requerimiento => requerimiento.save());
        });
      },

      guardarCambios(aplicacion, propiedad, fecha) {
        if (!aplicacion.get(propiedad)) {
          return;
        }

        fecha.setHours(0, 0, 0, 0);
        aplicacion.set(propiedad, fecha);
        aplicacion.save();
      },

      asociarProyecto(aplicacion) {
        const aplicacionId = aplicacion.get('id');

        if (!aplicacionId) {
          this.toast.error('No hay una aplicacion seleccionada.');
          return;
        }

        if (!aplicacion.get('fechaIngreso')) {
          this.toast.error('Ingrese la fecha de ingreso de la aplicación.');
          return;
        } // Si el tipo de servicio del proyecto de este requerimiento es ya administración
        // de planilla en FactoRH entonces intentar procesar y no levantar el modal


        let requerimiento = aplicacion.get('requerimiento');
        let proyecto = requerimiento.get('proyecto');
        let tipoServicio = proyecto.get('servicioAdquirido.tipoServicio');

        if (this.asignaDepartamentoFactorhAProyecto && 'ADMINISTRACION_PLANILLA_FACTORH' === tipoServicio) {
          if (!proyecto.get('factorhDepartamento')) {
            this.toast.error('El proyecto debe tener asociado un departamento de FactoRH.');
            return;
          }

          let proyectoCandidato = this.store.createRecord('proyecto-candidato', {
            proyecto: proyecto,
            requerimiento: requerimiento,
            candidato: aplicacion.get('candidato'),
            fechaAlta: aplicacion.get('fechaIngreso'),
            activo: true
          });
          this.loader.setIsLoading();
          return proyectoCandidato.save().then(() => {
            aplicacion.set('estatus', this.constants.APPLICATION_STATUS_CONTRATADO);
            aplicacion.save();
            this.toast.success('Registro creado correctamente.');
          }).catch(error => {
            var errorMessage = 'No se pudo asociar el candidato al proyecto';

            if (!Ember.isNone(error.errors)) {
              errorMessage += JSON.stringify(error.errors);
            }

            this.toast.error(errorMessage);
            throw error;
          }).finally(() => this.loader.setNotLoading());
        }

        this.transitionToRoute('requerimiento.record.ingreso.asociar-proyecto', {
          queryParams: {
            'aplicacionId': aplicacionId
          }
        });
      },

      marcarCompletada(aplicacion) {
        if (!aplicacion.get('fechaIngreso')) {
          this.toast.error('Ingrese la fecha de ingreso de la aplicación.');
          return;
        }

        this.loader.setIsLoading();
        aplicacion.set('estatus', this.constants.APPLICATION_STATUS_COMPLETADO);
        return aplicacion.save().then(() => {
          this.toast.success('Aplicación completada correctamente.');
        }).catch(error => {
          var errorMessage = 'No se pudo completar la aplicación.';

          if (!Ember.isNone(error.errors)) {
            errorMessage += JSON.stringify(error.errors);
          }

          this.toast.error(errorMessage);
          throw error;
        }).finally(() => this.loader.setNotLoading());
      },

      asociarPlanilla(aplicacion) {
        let aplicacionId = aplicacion.get('id');
        let requerimiento = aplicacion.get('requerimiento');

        if (!aplicacionId) {
          this.toast.error('No hay una aplicación seleccionada.');
          return;
        }

        if (!aplicacion.get('fechaIngreso')) {
          this.toast.error('Ingrese la fecha de ingreso de la aplicación.');
          return;
        }

        if (requerimiento.get('plaza') === 'TEMPORAL') {
          if (!aplicacion.get('fechaFinLabores')) {
            this.toast.error('Ingrese la fecha de fin de labores de la aplicación.');
            return;
          }
        }

        this.transitionToRoute('requerimiento.record.ingreso.asociar-planilla', 'new', {
          queryParams: {
            aplicacionId
          }
        });
      },

      verPlanilla(aplicacion) {
        if (!aplicacion || !aplicacion.get('id')) {
          this.toast.error('Ninguna aplicación seleccionada.');
          return;
        }

        if (!aplicacion.get('requerimiento.id')) {
          this.toast.error('La aplicación seleccionada no tiene requerimiento.');
          return;
        }

        if (!aplicacion.get('candidato.id')) {
          this.toast.error('La aplicación seleccionada no tiene candidato.');
          return;
        }

        this.loader.setIsLoading();
        return this.store.query('proyecto-candidato', {
          filter: {
            requerimiento: aplicacion.get('requerimiento.id'),
            candidato: aplicacion.get('candidato.id')
          }
        }).then(proyectoCandidato => {
          if (!proyectoCandidato.get('length')) {
            this.toast.error('No se encontró la planilla del candidato.');
            return;
          } else if (proyectoCandidato.get('length') > 1) {
            this.toast.error('Más de una planilla encontrada para el candidato.');
            return;
          }

          this.transitionToRoute('requerimiento.record.ingreso.asociar-planilla', proyectoCandidato.get('firstObject.id'));
        }).catch(error => {
          this.toast.error('Ocurrió un error al obtener la planilla del candidato. Error: ' + error.message);
          throw error;
        }).finally(() => this.loader.setNotLoading());
      },

      mandarLinkElTaier(aplicacion) {
        this.send('openModal', 'popups/simple-confirm', {
          title: `¿Está seguro que desea enviar el link al candidato?`,
          message: null,
          onSubmit: () => {
            aplicacion.set('notificado', true);
            aplicacion.save();
          }
        });
      },

      marcarFaseIngreso() {
        this.changeset.validate().then(() => {
          if (this.changeset.get('isInvalid')) {
            this.toast.error('Corrija los errores del formulario antes de realizar esta acción.');
            return;
          }

          this.changeset.set('fase', 'INGRESO');
          this.changeset.set('fechaFase', new Date());
          this.parentCtrl.submit();
        });
      }

    }
  });

  _exports.default = _default;
});