define("ods-app/models/proyecto", ["exports", "ember-data", "moment", "accounting/format-number"], function (_exports, _emberData, _moment, _formatNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    codigo: attr('string'),
    cliente: belongsTo('cliente'),
    clienteContacto: belongsTo('cliente-contacto'),
    clienteDatoFacturacion: belongsTo('cliente-dato-facturacion'),
    servicioAdquirido: belongsTo('servicio'),
    fechaSolicitud: attr('custom-date', {
      defaultValue() {
        return new Date();
      }

    }),
    factorhDepartamento: attr('string'),
    factorhDepartamentoStr: attr('string'),
    fechaAlta: attr('custom-date'),
    fechaRenovacion: attr('custom-date'),
    montoFacturar: attr('number', {
      defaultValue: 0
    }),
    anticipo: attr('boolean'),
    porcentajeAnticipo: attr('number', {
      defaultValue: 0
    }),
    montoAnticipo: attr('number', {
      defaultValue: 0
    }),
    estatus: attr('string'),
    cantidadRequerimientos: attr('number', {
      defaultValue: 0
    }),
    archivos: hasMany('proyecto-archivo'),
    porcentajeFee: attr('number', {
      defaultValue: 0
    }),
    cantidadFee: attr('number', {
      defaultValue: 0
    }),
    usaIsr: attr('boolean', {
      defaultValue: true
    }),
    porcentajeIsr: attr('number', {
      defaultValue: 0.0
    }),
    usaIva: attr('boolean', {
      defaultValue: true
    }),
    porcentajeIva: attr('number', {
      defaultValue: 0.0
    }),
    centroCosto: attr('string'),
    incluirCargos: attr('boolean', {
      defaultValue: true
    }),
    incluirPrestaciones: attr('boolean', {
      defaultValue: true
    }),
    razonCancelacion: belongsTo('razon-cancelacion'),
    observacionesCancelacion: attr('string'),
    nombre: attr('string'),
    statusToString: Ember.computed('estatus', function () {
      const status = this.constants.PROJECT_STATUS.findBy('key', this.estatus);
      return status ? status.name : this.status;
    }),
    fechaAltaFormatted: Ember.computed('fechaAlta', function () {
      const momentDate = (0, _moment.default)(this.fechaAlta);

      if (Ember.isNone(this.fechaAlta) || !momentDate.isValid()) {
        return '';
      }

      return momentDate.format('DD/MM/YYYY');
    }),
    fechaRenovacionFormatted: Ember.computed('fechaRenovacion', function () {
      const momentDate = (0, _moment.default)(this.fechaRenovacion);

      if (Ember.isNone(this.fechaRenovacion) || !momentDate.isValid()) {
        return '';
      }

      return momentDate.format('DD/MM/YYYY');
    }),
    fechaSolicitudFormatted: Ember.computed('fechaSolicitud', function () {
      const momentDate = (0, _moment.default)(this.fechaSolicitud);

      if (Ember.isNone(this.fechaSolicitud) || !momentDate.isValid()) {
        return '';
      }

      return momentDate.format('DD/MM/YYYY');
    }),
    montoFacturarFormatted: Ember.computed('montoFacturar', function () {
      return (0, _formatNumber.default)(this.get('montoFacturar'), 2);
    }),
    nombreProyecto: Ember.computed('codigo', 'cliente', 'servicioAdquirido', 'factorhDepartamentoStr', function () {
      let nombrePartes = [this.codigo];

      if (this.get('nombre')) {
        nombrePartes.push(this.get('nombre'));
      } // Aunque cliente no estuviera disponible aún, no da error porque devuelve un Proxy.


      if (this.get('cliente').get('nombre') && !this.get('nombre')) {
        nombrePartes.push(this.get('cliente').get('nombre'));
      }

      if (this.get('factorhDepartamentoStr')) {
        nombrePartes.push(this.get('factorhDepartamentoStr'));
      }

      let servicio = this.get('servicioAdquirido').get('tipoServicio'); // Esto si hay que validar porque si aún no está disponible, el search en constants devuelve undefined.

      if (servicio && !this.get('nombre')) {
        let servicioMapped = this.constants.SERVICE_TYPES.findBy('key', servicio);
        nombrePartes.push(servicioMapped.name);
      }

      return nombrePartes.join(' - ');
    }),
    nombreProyectoElTaier: Ember.computed('codigo', 'cliente', 'factorhDepartamentoStr', function () {
      let nombrePartes = [this.codigo];

      if (this.get('nombre')) {
        nombrePartes.push(this.get('nombre'));
      } // Aunque cliente no estuviera disponible aún, no da error porque devuelve un Proxy.


      if (this.get('cliente').get('nombre') && !this.get('nombre')) {
        nombrePartes.push(this.get('cliente').get('nombreComercial'));
      }

      if (this.get('factorhDepartamentoStr') && this.get('nombre')) {
        nombrePartes.push(this.get('factorhDepartamentoStr'));
      }

      return nombrePartes.join(' - ');
    })
  });

  _exports.default = _default;
});