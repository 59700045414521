define("ods-app/routes/requerimiento/record/aplicacion/asociar-aplicacion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params, transition) {
      const requerimiento = this.modelFor('requerimiento.record').record;
      let queries = {
        requirements: this.store.query('requerimiento', {
          filter: {
            'estatus': {
              'exact': [this.constants.REQUIREMENT_STATUS_BORRADOR, this.constants.REQUIREMENT_STATUS_VACANTE, this.constants.REQUIREMENT_STATUS_CONFIRMADO_PENDIENTE]
            },
            'codigo': {
              '!exact': requerimiento.get('codigo')
            }
          }
        })
      };

      if (!Ember.isNone(transition.queryParams.aplicacionId)) {
        const aplicacionId = transition.queryParams.aplicacionId;
        let queryAplicacion = {
          aplicacion: this.store.query('aplicacion', {
            filter: {
              id: aplicacionId
            }
          })
        };
        queries = Object.assign(queries, queryAplicacion);
      }

      return Ember.RSVP.hash(queries);
    },

    setupController(controller, model) {
      this._super(controller, model);

      const requerimiento = this.modelFor('requerimiento.record').record;
      controller.set('requerimiento', requerimiento);

      if (!Ember.isNone(model.aplicacion)) {
        let aplicacion = model.aplicacion.get('firstObject');
        controller.set('aplicacion', aplicacion);
      }
    }

  });

  _exports.default = _default;
});