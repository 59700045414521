define("ods-app/models/messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    REQUERIMIENTO_DESHABILITADO: 'El requerimiento está deshabilitado.',
    APLICACION_RECHAZADA: 'Esta aplicación está rechazada, no puede realizar ningún cambio.',
    APLICACION_DESISTIDA: 'Esta aplicación está desistida, no puede realizar ningún cambio.',
    APLICACION_CONTRATADA: 'La aplicación ya ha sido contratada.',
    APLICACION_COMPLETADA: 'La aplicación ya ha sido completada.',
    APLICACION_CONFIRMADA_PENDIENTE_INGRESO: 'Esta aplicación está confirmada pendiente de ingreso, no puede realizar ningún cambio.',
    APLICACION_PERTENECE_OTRO_REQUERIMIENTO: 'La aplicación pertenece a otro requerimiento.',
    APLICACION_EN_TERNA: 'La aplicacion ya pertenece a una terna.',
    APLICACION_NO_RECHAZADA: 'Esta aplicación no esta rechazada no se puede revivir.',
    TERNA_ACEPTADA: 'La aplicacion ya pertenece a una terna.'
  };
  _exports.default = _default;
});