define("ods-app/helpers/date-min", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute([firstDate, secondDate]) {
      let min = _moment.default.min((0, _moment.default)(firstDate), (0, _moment.default)(secondDate));

      return min;
    }

  });

  _exports.default = _default;
});