define("ods-app/models/bitacora-tipo-evento", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    codigo: attr('string'),
    nombre: attr('string'),
    descripcion: attr('string'),
    activo: attr('boolean', {
      defaultValue: true
    }),
    activoFormatted: Ember.computed('activo', function () {
      return this.activo ? 'Sí' : 'No';
    })
  });

  _exports.default = _default;
});