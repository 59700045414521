define("ods-app/controllers/popups/desistir-aplicacion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    title: null,
    message: null,
    showCancel: true,
    fase: null,
    tipo: null,
    buttonActions: Ember.computed(function () {
      return [];
    }),
    observaciones: null,
    razonCancelacion: null,
    razonesCancelacion: Ember.computed('fase', function () {
      return Ember.RSVP.resolve(this.store.query('razon-cancelacion', {
        filter: {
          tipo: this.get('tipo'),
          activo: 1
        }
      })).then(razones => {
        return razones.filter(razon => {
          return razon.fases && razon.fases.includes(this.get('fase'));
        });
      });
    }),

    onSubmit() {},

    actions: {
      onSubmit(methodName) {
        let method = methodName && this.get(methodName) || this.get('onSubmit');
        return method(this.get('observaciones'), this.get('razonCancelacion'));
      }

    }
  });

  _exports.default = _default;
});