define("ods-app/routes/area", ["exports", "servir-ember-utilities/routes/abstract-module-index", "ods-app/mixins/permission-validator"], function (_exports, _abstractModuleIndex, _permissionValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend(_permissionValidator.default, {
    routePermission: 'VIEW_AREAS',
    breadcrumbsRoute: Ember.computed(function () {
      return [{
        name: 'Áreas',
        route: 'area'
      }];
    }),

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('reload', true);
    }

  });

  _exports.default = _default;
});