define("ods-app/templates/requerimiento/record/bitacora", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M31Di+6U",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"pull-right\"],[8],[0,\"\\n\"],[4,\"if\",[[26,\"and\",[[26,\"not\",[[22,[\"record\",\"isNew\"]]],null],[26,\"and\",[[26,\"not-eq\",[[22,[\"changeset\",\"estatus\"]],\"BORRADOR\"],null],[26,\"not-eq\",[[22,[\"changeset\",\"estatus\"]],\"EN_REVISION\"],null],[26,\"not-eq\",[[22,[\"changeset\",\"estatus\"]],\"PENDIENTE_DE_AUTORIZAR\"],null]],null],[26,\"not\",[[22,[\"changeset\",\"estaDeshabilitado\"]]],null],[26,\"not\",[[22,[\"changeset\",\"ternaActiva\",\"aceptada\"]]],null]],null]],null,{\"statements\":[[4,\"link-to\",[\"requerimiento.record.bitacora.record\",\"new\"],[[\"classNames\"],[\"btn btn-success btn-xs\"]],{\"statements\":[[0,\"      \"],[6,\"i\"],[10,\"class\",\"fa fa-plus-circle\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n      Nuevo evento\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[1,[26,\"tables/paginated-table\",null,[[\"columns\",\"modelName\",\"modelClassObject\",\"modelFilters\",\"modelSortingProperties\",\"includedRelationships\",\"load\"],[[22,[\"columns\"]],\"bitacora-evento\",[22,[\"modelClass\"]],[22,[\"modelFilters\"]],[22,[\"modelSortingProperties\"]],[22,[\"includedRelationships\"]],[22,[\"reload\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/requerimiento/record/bitacora.hbs"
    }
  });

  _exports.default = _default;
});