define("ods-app/routes/reporte-plazas", ["exports", "servir-ember-utilities/mixins/route/has-dependent-fields", "servir-ember-utilities/routes/abstract-module-index", "ods-app/mixins/permission-validator", "ember-changeset-validations/validators", "ods-app/mixins/ods-app-file-saver", "ember-changeset-validations", "ember-changeset", "moment"], function (_exports, _hasDependentFields, _abstractModuleIndex, _permissionValidator, _validators, _odsAppFileSaver, _emberChangesetValidations, _emberChangeset, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    hash
  } = Ember.RSVP;

  var _default = _abstractModuleIndex.default.extend(_permissionValidator.default, _hasDependentFields.default, _odsAppFileSaver.default, {
    currentUser: Ember.inject.service(),
    toast: Ember.inject.service(),
    loader: Ember.inject.service(),
    user: Ember.computed.alias('currentUser.user'),
    routePermission: 'VIEW_REPORTS_PLAZAS',
    breadcrumbsRoute: Ember.computed(function () {
      return [{
        name: 'Reporte plazas',
        route: 'reporte-plazas'
      }];
    }),
    validations: Ember.computed(function () {
      return {
        tipo: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    // dependentRelatedAttributes: computed(function() {
    //   return [
    //     {
    //       dependentAttributePath: 'changeset.departamento',
    //       dependentAttributeModelName: 'departamento',
    //       dependedUponAttributeName: 'pais',
    //       dependentAttributeDependentPath: 'pais'
    //     },
    //     {
    //       dependentAttributePath: 'changeset.municipio',
    //       dependentAttributeModelName: 'municipio',
    //       dependedUponAttributeName: 'changeset.departamento',
    //       dependentAttributeDependentPath: 'departamento'
    //     },
    //   ];
    // }),
    model()
    /*params, transition*/
    {
      this.loader.setIsLoading();
      return hash({
        clients: this.store.query('cliente', {}),
        projects: this.store.query('proyecto', {}),
        // requirements: this.store.query('requerimiento', {}),
        reclutadores: this.store.query('usuario', {}),
        reclutamientos: this.store.query('fuente-reclutamiento', {
          filter: {
            activo: 1
          }
        }),
        paises: this.store.query('pais', {}),
        departamentos: this.store.query('departamento', {}),
        municipios: this.store.query('municipio', {})
      }).then(data => {
        let filterModel = this.store.createRecord('reporte-plazas');
        return Ember.merge(data, {
          filterModel
        });
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      this.loader.setNotLoading();
      let changeset = new _emberChangeset.default(model.filterModel, (0, _emberChangesetValidations.default)(this.get('validations')), this.get('validations'));
      controller.set('changeset', changeset);
    },

    actions: {
      updatePais(changeset, propertyName, selectedValue = null) {
        changeset.set(propertyName, selectedValue);
        this.refreshOptionsForDependentOnSelects('pais', selectedValue);
      },

      updateDepartamento(changeset, propertyName, selectedValue = null) {
        changeset.set(propertyName, selectedValue);
        this.refreshOptionsForDependentOnSelects('changeset.departamento', selectedValue);
      },

      updateMunicipio(changeset, propertyName, selectedValue = null) {
        changeset.set(propertyName, selectedValue);
      },

      generarReporte() {
        this.loader.setIsLoading();
        let changeset = this.controller.get('changeset');
        return changeset.validate().then(() => {
          if (!changeset.get('isValid')) {
            this.loader.setNotLoading();
            this.toast.error('Debe seleccionar un tipo de reporte.');
            return;
          }

          if (changeset.get('tipo') == this.constants.REPORTS_JOB_TYPES_CONTRATADAS || changeset.get('tipo') == this.constants.REPORTS_JOB_TYPES_CONTRATADAS_FUENTE) {
            changeset.set('status', ['VACANTE']);
          }

          if (!changeset.get('status')) {
            this.loader.setNotLoading();
            this.toast.error('Debe seleccionar un estatus.');
            return;
          }

          let startDate = changeset.get('startDate');
          let endDate = changeset.get('endDate');
          let filters = {};

          if (changeset.get('tipo') == this.constants.REPORTS_JOB_TYPES_CONTRATADAS || changeset.get('tipo') == this.constants.REPORTS_JOB_TYPES_CONTRATADAS_FUENTE) {
            filters = {
              clientes: changeset.get('clientes').map(p => p.id),
              proyectos: changeset.get('proyectos').map(p => p.id),
              tipo: changeset.get('tipo') || null,
              startDate: startDate ? (0, _moment.default)(startDate).format() : null,
              endDate: endDate ? (0, _moment.default)(endDate).format() : null,
              pais: changeset.get('pais.id') || null,
              departamento: changeset.get('departamento.id') || null,
              municipio: changeset.get('municipio.id') || null,
              reclutadores: changeset.get('reclutadores').map(p => p.id) || null,
              status: changeset.get('status') || null,
              fuentesReclutamiento: changeset.get('fuentesReclutamiento').map(p => p.id) || null
            };
          } else {
            filters = {
              clientes: changeset.get('clientes').map(p => p.id),
              proyectos: changeset.get('proyectos').map(p => p.id),
              tipo: changeset.get('tipo') || null,
              startDate: startDate ? (0, _moment.default)(startDate).format() : null,
              endDate: endDate ? (0, _moment.default)(endDate).format() : null,
              pais: changeset.get('pais.id') || null,
              departamento: changeset.get('departamento.id') || null,
              municipio: changeset.get('municipio.id') || null,
              reclutadores: changeset.get('reclutadores').map(p => p.id) || null,
              status: changeset.get('status').map(p => p.key) || null,
              fuentesReclutamiento: changeset.get('fuentesReclutamiento').map(p => p.id) || null
            };
          }

          this.getFileFromServer('/reporte-plazas', filters);
        });
      }

    }
  });

  _exports.default = _default;
});