define("ods-app/controllers/candidato/record", ["exports", "servir-ember-utilities/controllers/abstract-module-record", "ods-app/utils/common"], function (_exports, _abstractModuleRecord, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    ajaxService: Ember.inject.service(),
    toast: Ember.inject.service(),
    loader: Ember.inject.service(),
    postSaveTransitionRoute: null,

    postSave(record) {
      if (this.get('isNew')) {
        // TODO: Research about the best way to update the URL with record id.
        return this.transitionToRoute(this.get('routeName'), record.id);
      }

      this.record.get('aplicaciones').forEach(aplicacion => {
        aplicacion.set('fuenteReclutamiento', this.record.fuenteReclutamiento);
        aplicacion.set('nombreReferido', this.record.nombreReferido);
        aplicacion.save();
      });
      return this._super(...arguments);
    },

    actions: {
      crearEmpleadoEnFactorh() {
        let candidatoId = this.record.get('id');
        this.loader.setIsLoading();
        return this.get('ajaxService').post('/factorh-empleados', {
          data: {
            candidatoId
          }
        }).then(res => {
          this.toast.success(res.message || 'Empleado creado correctamente en FactoRH.');
        }).catch(error => {
          this.toast.error('No se pudo crear el empleado en FactoRH.');
          throw error;
        }).finally(() => this.loader.setNotLoading());
      },

      setFavorite(value) {
        return this.changeset.validate().then(() => {
          if (!this.changeset.get('isValid')) {
            this.toast.error('Corrija los errores del formulario primero.');
            return;
          }

          this.changeset.set('esFavorito', value);
          return this.submit().catch(error => {
            this.record.rollbackAttributes();
            (0, _common.handleChangesetSaveErrors)(this.changeset, error, this.toast);
          });
        });
      }

    }
  });

  _exports.default = _default;
});