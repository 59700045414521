define("ods-app/controllers/candidato/record/informacion", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentDate: null,
    ajaxService: Ember.inject.service(),
    currentDateTitle: Ember.computed('currentDate', function () {
      if (!this.get('currentDate')) {
        return;
      }

      let currentDate = (0, _moment.default)(this.get('currentDate'));
      return 'Debe ser anterior al ' + currentDate.format('DD/MM/YYYY');
    }),
    validateCodigoActions: Ember.computed(function () {
      return [{
        title: 'Validar código en factoRH',
        iconName: 'fa-check-circle-o',
        action: this.actions.validarCodigoEnFactoRH.bind(this)
      }];
    }),
    actions: {
      validateDPI(changeset, propertyName, value) {
        if (value.length < 13) {
          changeset.set(propertyName, value);
          return;
        }

        this.store.query('candidato', {
          filter: {
            uid: value
          }
        }).then(candidato => {
          changeset._deleteKey('_errors', propertyName);

          if (candidato.get('firstObject')) {
            changeset.addError(propertyName, ['DPI ya registrado en el sistema']);
            return;
          }
        });
        changeset.set(propertyName, value);
      },

      validatePasaporte(changeset, propertyName, value) {
        if (value.length < 10) {
          return;
        }

        this.store.query('candidato', {
          filter: {
            pasaporte: value
          }
        }).then(candidato => {
          changeset._deleteKey('_errors', propertyName);

          if (candidato.get('firstObject')) {
            changeset.addError(propertyName, ['Pasaporte ya registrado en el sistema']);
            return;
          }
        });
        changeset.set(propertyName, value);
      },

      validarCodigoEnFactoRH() {
        let codigoFactorh = this.get('changeset.codigoFactorh');

        if (!codigoFactorh) {
          this.toast.error('Definir un código de empleado');
          return;
        }

        this.get('ajaxService').request(`/factorh-empleado/existe/${codigoFactorh}`).then(data => {
          if (!data.data.existe) {
            this.toast.success('Código de empleado disponible en factoRH');
          } else {
            this.toast.error('El código de empleado ya existe en factorh');
          }
        }).catch(error => {
          this.toast.error('Ocurrió un error al consultar el código en factoRH.');
          throw error;
        });
      },

      onChangeFechaNacimiento() {
        let fechaNacimiento = this.get('changeset.fechaNacimiento');
        this.changeset.set('fechaNacimiento', fechaNacimiento);

        if (!fechaNacimiento) {
          return;
        }

        this.changeset.set('edad', (0, _moment.default)().diff(fechaNacimiento, 'years'));
      }

    }
  });

  _exports.default = _default;
});