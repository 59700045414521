define("ods-app/routes/requerimiento/record/informacion", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute", "servir-ember-utilities/mixins/route/has-dependent-fields", "ods-app/mixins/permission-validator"], function (_exports, _isTabSubroute, _hasDependentFields, _permissionValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, _hasDependentFields.default, _permissionValidator.default, {
    routePermission: 'VIEW_RQ_TAB_GENERAL_DATA',
    noPermissionTransitionRoute: 'requerimiento',
    dependentRelatedAttributes: Ember.computed(function () {
      return [{
        dependentAttributePath: 'changeset.departamento',
        dependentAttributeModelName: 'departamento',
        dependedUponAttributeName: 'pais',
        dependentAttributeDependentPath: 'pais'
      }, {
        dependentAttributePath: 'changeset.municipio',
        dependentAttributeModelName: 'municipio',
        dependedUponAttributeName: 'changeset.departamento',
        dependentAttributeDependentPath: 'departamento'
      }];
    }),

    model() {
      let modelData = this._super(...arguments); // Queremos levantar en el storage de ember los clientes que tengan proyectos,
      // esto para para efectos del despliegue del select de proyectos


      return this.store.query('cliente', {
        filter: {
          proyectos: {
            exists: 1
          }
        }
      }).then(() => modelData);
    },

    setupController(controller) {
      this._super(...arguments);

      controller.set('reload', true);
    },

    actions: {
      updatePais(changeset, propertyName, selectedPais = null) {
        changeset.set(propertyName, selectedPais);
        this.refreshOptionsForDependentOnSelects('pais', selectedPais);
      },

      updateDepartamento(changeset, propertyName, selectedDepartamento = null) {
        changeset.set(propertyName, selectedDepartamento);
        this.refreshOptionsForDependentOnSelects('changeset.departamento', selectedDepartamento);
      },

      updateMunicipio(changeset, propertyName, selectedMunicipio = null) {
        changeset.set(propertyName, selectedMunicipio);
      },

      onChangeSustituye(candidato) {
        if (!candidato) {
          return;
        }

        if (!candidato.requerimientoId) {
          this.controller.changeset.set('sustituye', candidato.nombre);
          return;
        }

        this.store.findRecord('requerimiento', candidato.requerimientoId).then(requerimiento => {
          let changeset = this.controller.changeset;
          Ember.setProperties(changeset, {
            sustituye: candidato.nombre,
            nombrePlaza: requerimiento.get('nombrePlaza'),
            nombreJefeInmediato: requerimiento.get('nombreJefeInmediato'),
            supervisorOperativo: requerimiento.get('supervisorOperativo'),
            ubicacion: requerimiento.get('ubicacion'),
            jornada: requerimiento.get('jornada'),
            diasHorario: requerimiento.get('diasHorario'),
            escolaridad: requerimiento.get('escolaridad'),
            idiomas: requerimiento.get('idiomas'),
            programasComputacion: requerimiento.get('programasComputacion'),
            anosExperiencia: requerimiento.get('anosExperiencia'),
            areasExperiencia: requerimiento.get('areasExperiencia'),
            edad: requerimiento.get('edad'),
            genero: requerimiento.get('genero'),
            estadoCivil: requerimiento.get('estadoCivil'),
            religion: requerimiento.get('religion'),
            vehiculo: requerimiento.get('vehiculo'),
            tipoLicencia: requerimiento.get('tipoLicencia'),
            infoVehiculo: requerimiento.get('infoVehiculo'),
            funcionesBasicas: requerimiento.get('funcionesBasicas'),
            competencias: requerimiento.get('competencias'),
            responsabilidades: requerimiento.get('responsabilidades'),
            personalBajoCargo: requerimiento.get('personalBajoCargo'),
            presentacion: requerimiento.get('presentacion'),
            pais: requerimiento.get('pais')
          });
          this.refreshOptionsForDependentOnSelects('pais', requerimiento.get('pais'));
          changeset.set('departamento', requerimiento.get('departamento'));
          this.refreshOptionsForDependentOnSelects('changeset.departamento', requerimiento.get('departamento'));
          changeset.set('municipio', requerimiento.get('municipio'));
        });
      }

    }
  });

  _exports.default = _default;
});