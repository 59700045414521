define("ods-app/controllers/cliente/record/contratos", ["exports", "servir-ember-utilities/controllers/abstract-module-index"], function (_exports, _abstractModuleIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    modelName: 'proyecto',
    editRoute: 'proyecto.record.servicio',
    modelFilters: Ember.computed('record', function () {
      return [{
        selectedFilter: {
          filterName: 'servicio.tipoServicio'
        },
        value: 'ADMINISTRACION_PLANILLA_FACTORH'
      }];
    }),
    columns: Ember.computed(function () {
      return Ember.A([{
        label: 'Código',
        valuePath: 'codigo',
        sortable: false,
        width: '100px'
      }, {
        label: 'Estatus',
        valuePath: 'statusToString',
        sortable: false,
        width: '100px'
      }, {
        label: 'Fecha de solicitutd',
        valuePath: 'fechaSolicitudFormatted',
        sortable: false,
        width: '100px'
      }, {
        label: 'Fecha de alta',
        valuePath: 'fechaAltaFormatted',
        sortable: false,
        width: '100px'
      }, {
        label: 'Fecha de renovación',
        valuePath: 'fechaRenovacionFormatted',
        sortable: false,
        width: '100px'
      }, {
        label: 'Servicio',
        valuePath: 'servicioAdquirido.nombre',
        sortable: false,
        width: '150px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '100px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          } // delete: {
          //   title: 'Eliminar',
          //   buttonClass: 'btn-danger',
          //   icon: 'trash-o',
          //   action: this.actions.delete.bind(this),
          // }

        }
      }]);
    })
  });

  _exports.default = _default;
});