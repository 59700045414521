define("ods-app/controllers/proyecto/record/requerimientos", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/models/requerimiento"], function (_exports, _abstractModuleIndex, _requerimiento) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    user: Ember.computed.readOnly('currentUser.user'),
    modelName: 'requerimiento',
    modelClass: _requerimiento.default,
    editRoute: 'requerimiento.record',
    reload: true,
    includedRelationships: Ember.computed(function () {
      return Ember.A(['ternas']);
    }),
    modelSortingProperties: Ember.computed(function () {
      return Ember.A([{
        propertyName: 'id'
      }]);
    }),
    modelFilters: Ember.computed('record', function () {
      return [{
        selectedFilter: {
          filterName: 'proyecto.id'
        },
        value: this.record.get('id') || -1
      }];
    }),
    columnsGeneral: Ember.computed(function () {
      let columns = [{
        label: 'Código',
        valuePath: 'codigo',
        sortable: true,
        width: '90px',
        filtrable: true,
        filterName: 'codigo'
      }, {
        label: 'Fecha de solicitud',
        valuePath: 'fechaSolicitudFormatted',
        sortable: false,
        width: '120px'
      }];

      if (this.user.get('permissions.VIEW_DETAIL_REQUIREMENT_COLUMNS')) {
        columns.push({
          label: 'Situación',
          valuePath: 'ternaActiva.situacion',
          sortable: false,
          width: '130px'
        }, {
          label: 'Es urgente',
          valuePath: 'esUrgenteFormatted',
          sortable: false,
          width: '80px'
        });
      }

      columns.push({
        label: 'Estatus',
        valuePath: 'statusToString',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'estatus'
      }, {
        label: 'Fase',
        valuePath: 'faseToString',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'fase',
        filterInputType: 'select',
        filterLabelPath: 'name',
        filterTargetPath: 'key',
        filterOptions: this.constants.DEFAULT_PHASES
      }, {
        label: 'Reclutador',
        valuePath: 'reclutador.username',
        sortable: false,
        width: '150px',
        filtrable: true,
        filterName: 'reclutador'
      }, {
        label: 'Nombre de la plaza',
        valuePath: 'nombrePlaza',
        sortable: true,
        width: '160px',
        filtrable: true,
        filterName: 'nombrePlaza'
      });

      if (this.user.get('permissions.VIEW_DETAIL_REQUIREMENT_COLUMNS')) {
        columns.push({
          label: 'Fecha de seguimiento',
          valuePath: 'fechaSeguimientoProgramadaFormatted',
          sortable: false,
          width: '120px'
        }, {
          label: 'Fecha vencimiento',
          valuePath: 'ternaActiva.fechaVencimientoEnvioFormatted',
          sortable: false,
          width: '120px'
        }, {
          label: 'Tiempo disponible',
          valuePath: 'ternaActiva.tiempoDisponible',
          sortable: false,
          width: '80px'
        });
      }

      columns.push({
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '100px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      });
      return Ember.A(columns);
    }),
    columnsClienteExterno: Ember.computed(function () {
      let columns = [{
        label: 'Código',
        valuePath: 'codigo',
        sortable: true,
        width: '60px',
        filtrable: true,
        filterName: 'codigo'
      }, {
        label: 'Fecha solicitud',
        valuePath: 'fechaSolicitudFormatted',
        sortable: true,
        width: '90px',
        sortingPropertyName: 'fechaSolicitud',
        sorted: true,
        ascending: false
      }, {
        label: 'Fase',
        valuePath: 'faseToString',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'fase',
        filterInputType: 'select',
        filterLabelPath: 'name',
        filterTargetPath: 'key',
        filterOptions: this.constants.DEFAULT_PHASES
      }, {
        label: 'Reclutador',
        valuePath: 'reclutador.username',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'reclutador'
      }, {
        label: 'Cliente',
        valuePath: 'proyecto.cliente.nombreComercial',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'proyecto.cliente.nombreComercial'
      }, {
        label: 'Nombre de la plaza',
        valuePath: 'nombrePlaza',
        sortable: true,
        width: '140px',
        filtrable: true,
        filterName: 'nombrePlaza'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '100px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }];
      return Ember.A(columns);
    }),
    columns: Ember.computed('user', 'columnsGeneral', 'columnsClienteExterno', function () {
      if (this.user.get('role.codigo') === 'CLIENTE') {
        return this.columnsClienteExterno;
      }

      return this.columnsGeneral;
    }),

    reloadModel() {
      this.set('reload', true);
    }

  });

  _exports.default = _default;
});