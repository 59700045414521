define("ods-app/routes/requerimiento/record/bitacora/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    routeRecordIdentifierDynamicSegment: 'bitacora_id',
    modelName: 'bitacora-evento',
    indexRoute: 'requerimiento.record.bitacora',
    currentUser: Ember.inject.service(),
    validations: Ember.computed(function () {
      let validaciones = {
        fecha: (0, _validators.validatePresence)({
          presence: true
        }),
        tipoEvento: (0, _validators.validatePresence)({
          presence: true
        })
      };
      return validaciones;
    }),
    relatedModelsToQuery: Ember.computed(function () {
      let relatedModels = [{
        name: 'tiposEvento',
        modelName: 'bitacora-tipo-evento',
        queryParams: {
          filter: {
            activo: 1
          }
        }
      }];
      return relatedModels;
    }),

    createRecordInstance() {
      const requerimiento = this.modelFor('requerimiento.record').record;
      const modelName = this.get('modelName');
      return this.store.createRecord(modelName, {
        requerimiento
      });
    },

    actions: {
      willTransition() {
        return true;
      }

    }
  });

  _exports.default = _default;
});