define("ods-app/routes/candidato/record/informacion", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute", "servir-ember-utilities/mixins/route/has-dependent-fields", "moment"], function (_exports, _isTabSubroute, _hasDependentFields, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, _hasDependentFields.default, {
    setupController(controller) {
      this._super(...arguments);

      let date = new Date();
      date = (0, _moment.default)(date).subtract(16, 'years');
      controller.set('currentDate', date);
    },

    dependentRelatedAttributes: Ember.computed(function () {
      return [{
        dependentAttributePath: 'changeset.departamentoNacimiento',
        dependentAttributeModelName: 'departamento',
        dependedUponAttributeName: 'paisNacimiento',
        dependentAttributeDependentPath: 'pais'
      }, {
        dependentAttributePath: 'changeset.municipioNacimiento',
        dependentAttributeModelName: 'municipio',
        dependedUponAttributeName: 'changeset.departamentoNacimiento',
        dependentAttributeDependentPath: 'departamento'
      }, {
        dependentAttributePath: 'changeset.departamentoVecindad',
        dependentAttributeModelName: 'departamento',
        dependedUponAttributeName: 'paisVecindad',
        dependentAttributeDependentPath: 'pais'
      }, {
        dependentAttributePath: 'changeset.municipioVecindad',
        dependentAttributeModelName: 'municipio',
        dependedUponAttributeName: 'changeset.departamentoVecindad',
        dependentAttributeDependentPath: 'departamento'
      }, {
        dependentAttributePath: 'changeset.subarea',
        dependentAttributeModelName: 'subarea',
        dependedUponAttributeName: 'area',
        dependentAttributeDependentPath: 'area'
      }];
    }),
    actions: {
      updatePaisNacimiento(changeset, propertyName, selectedValue = null) {
        changeset.set(propertyName, selectedValue);
        this.refreshOptionsForDependentOnSelects('paisNacimiento', selectedValue);
      },

      updateDepartamentoNacimiento(changeset, propertyName, selectedValue = null) {
        changeset.set(propertyName, selectedValue);
        this.refreshOptionsForDependentOnSelects('changeset.departamentoNacimiento', selectedValue);
      },

      updateMunicipioNacimiento(changeset, propertyName, selectedValue = null) {
        changeset.set(propertyName, selectedValue);
      },

      updatePaisVecindad(changeset, propertyName, selectedValue = null) {
        changeset.set(propertyName, selectedValue);
        this.refreshOptionsForDependentOnSelects('paisVecindad', selectedValue);
      },

      updateDepartamentoVecindad(changeset, propertyName, selectedValue = null) {
        changeset.set(propertyName, selectedValue);
        this.refreshOptionsForDependentOnSelects('changeset.departamentoVecindad', selectedValue);
      },

      updateMunicipioVecindad(changeset, propertyName, selectedValue = null) {
        changeset.set(propertyName, selectedValue);
      },

      onAreaUpdated() {
        this.refreshOptionsForDependentOnSelects('area', this.controller.changeset.get('area'));
      }

    }
  });

  _exports.default = _default;
});