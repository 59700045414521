define("ods-app/controllers/requerimiento/record/investigacion", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/models/aplicacion", "ods-app/helpers/is-empresa"], function (_exports, _abstractModuleIndex, _aplicacion, _isEmpresa) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    parentCtrl: Ember.inject.controller('requerimiento.record'),
    modelClass: _aplicacion.default,
    editRoute: 'aplicacion.record',
    reload: true,
    currentUser: Ember.inject.service(),
    modelSortingProperties: Ember.computed(function () {
      return Ember.A([{
        propertyName: 'id'
      }]);
    }),
    modelFilters: Ember.computed('record', function () {
      return [{
        selectedFilter: {
          filterName: 'requerimiento'
        },
        value: this.get('record.id') || -1
      }, {
        selectedFilter: {
          filterName: 'custom.estatusHistorico'
        },
        value: this.constants.APPLICATION_STATUS_INVESTIGACION
      }];
    }),
    columns: Ember.computed(function () {
      let currentStatus = [this.constants.APPLICATION_STATUS_INVESTIGACION, this.constants.APPLICATION_STATUS_RECHAZADO_INVESTIGACION, this.constants.APPLICATION_STATUS_DESISTIO_INVESTIGACION];
      let actions = {
        edit: {
          title: 'Editar',
          buttonClass: 'btn-primary',
          icon: 'pencil-square-o',
          action: this.actions.edit.bind(this)
        }
      };
      actions.enviarATerna = {
        title: 'Enviar a terna activa',
        buttonClass: 'btn-success',
        icon: (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'SERVINOVA') ? 'user' : 'share',
        action: this.actions.asignarAplicacionATernaActiva.bind(this)
      };
      actions.desistir = {
        title: 'Desistir aplicación',
        buttonClass: 'btn-warning',
        icon: 'times',
        action: this.actions.desistirAplicacion.bind(this)
      };
      actions.rechazar = {
        title: 'Rechazar aplicación',
        buttonClass: 'btn-danger',
        icon: 'times',
        action: this.actions.rechazarAplicacion.bind(this)
      };

      if (this.get('currentUser').hasPermission('REQUIREMENT_REVIVE_APPLICATION')) {
        actions.revivir = {
          title: 'Revivir candidato',
          buttonClass: 'btn-success',
          icon: 'arrow-up',
          action: this.actions.revivirAplicacion.bind(this)
        };
      }

      return Ember.A([{
        label: 'Código',
        valuePath: 'codigo',
        sortable: true,
        width: '100px',
        filtrable: true,
        filterName: 'codigo',
        currentStatus: currentStatus,
        cellComponent: 'tables/cell/record-link',
        route: 'aplicacion.record.historial',
        idRoute: 'id',
        linkTitle: 'Ver historial de aplicación'
      }, {
        label: 'Candidato',
        valuePath: 'candidato.nombreCompleto',
        sortable: true,
        sortingPropertyName: 'candidato.primerNombre',
        width: '100px',
        filtrable: true,
        filterName: 'candidato.primerNombre',
        currentStatus: currentStatus,
        cellComponent: 'requerimiento-aplicacion-cell'
      }, {
        label: 'Ocupación',
        valuePath: 'candidato.ocupacion',
        sortable: true,
        width: '100px',
        currentStatus: currentStatus,
        cellComponent: 'requerimiento-aplicacion-cell'
      }, {
        label: 'Expectativa salarial',
        valuePath: 'expectativaSalarialFormatted',
        sortable: false,
        width: '100px',
        align: 'right',
        filtrable: true,
        filterName: 'expectativaSalarial',
        currentStatus: currentStatus,
        cellComponent: 'requerimiento-aplicacion-cell'
      }, {
        label: 'Estatus',
        valuePath: 'statusToString',
        sortable: true,
        sortingPropertyName: 'estatus',
        width: '100px',
        filtrable: true,
        filterName: 'estatus',
        currentStatus: currentStatus,
        cellComponent: 'requerimiento-aplicacion-cell'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        width: '100px',
        table: this,
        sortable: false,
        objectActions: actions
      }]);
    }),

    reloadModel() {
      this.set('reload', true);
    },

    actions: {
      asignarAplicacionATernaActiva(aplicacion) {
        let controller = this;

        if (controller.changeset.get('estaDeshabilitado')) {
          this.toast.error(this.messages.REQUERIMIENTO_DESHABILITADO);
          return;
        } else if (controller.get('record.ternas.length') == 0) {
          this.toast.error('No hay ninguna terna asociada al requerimiento.');
          return;
        } else if (aplicacion.esRechazada) {
          this.toast.error(this.messages.APLICACION_RECHAZADA);
          return;
        } else if (aplicacion.esDesistida) {
          this.toast.error(this.messages.APLICACION_DESISTIDA);
          return;
        }

        let ternaActiva = controller.get('record.ternas').filterBy('activo').get('firstObject');

        if (!ternaActiva) {
          this.toast.error('Todavía no se ha iniciado el proceso de reclutamiento.');
          return;
        }

        if (ternaActiva.get('aceptada')) {
          this.toast.error('la terna ya ha sido aceptada, no puede realizar ningún cambio.');
          return;
        }

        aplicacion.set('terna', ternaActiva);
        aplicacion.set('estatus', this.constants.APPLICATION_STATUS_EN_TERNA);
        aplicacion.save().then(() => {
          this.reloadModel();
          this.toast.success('Aplicación agregada a terna activa');
        });
      },

      rechazarAplicacion(aplicacion) {
        let controller = this;

        if (controller.changeset.get('estaDeshabilitado')) {
          this.toast.error(this.messages.REQUERIMIENTO_DESHABILITADO);
          return;
        } else if (aplicacion.esRechazada) {
          this.toast.error(this.messages.APLICACION_RECHAZADA);
          return;
        } else if (aplicacion.esDesistida) {
          this.toast.error(this.messages.APLICACION_DESISTIDA);
          return;
        }

        if (aplicacion.get('estatus') !== this.constants.APPLICATION_STATUS_INVESTIGACION) {
          this.toast.error('El candidato ya no se encuentra en esta fase');
          return;
        }

        this.send('openModal', 'popups/enviar-lista-negra', {
          title: 'Rechazar aplicación',
          tipo: 'APLICACION',
          fase: 'INVESTIGACION',
          razonCancelacion: null,
          razonListaNegra: null,
          buttonActions: [{
            title: 'Rechazar aplicación',
            methodName: 'noEnviarAListaNegra',
            type: 'warning'
          }, {
            title: 'Enviar candidato lista negra',
            methodName: 'enviarAListaNegra',
            type: 'danger'
          }],
          enviarAListaNegra: (razonListaNegra, razonCancelacion) => {
            if (!razonListaNegra || !razonCancelacion) {
              this.toast.error('Campo de razón requerido');
              return;
            }

            aplicacion.set('estatus', this.constants.APPLICATION_STATUS_RECHAZADO_INVESTIGACION);
            aplicacion.set('observacionesRechazo', razonListaNegra);
            aplicacion.set('razonRechazo', razonCancelacion.get('nombre'));
            aplicacion.save().then(() => aplicacion.get('candidato')).then(candidato => {
              candidato.set('listaNegra', true);
              candidato.set('razonListaNegra', razonListaNegra);
              candidato.save().then(() => {
                this.reloadModel();
                this.toast.success('Aplicación rechazada');
              });
            });
          },
          noEnviarAListaNegra: (razonListaNegra, razonCancelacion) => {
            if (!razonListaNegra || !razonCancelacion) {
              this.toast.error('Campo de razón requerido');
              return;
            }

            aplicacion.set('estatus', this.constants.APPLICATION_STATUS_RECHAZADO_INVESTIGACION);
            aplicacion.set('observacionesRechazo', razonListaNegra);
            aplicacion.set('razonRechazo', razonCancelacion.get('nombre'));
            aplicacion.save().then(() => {
              this.reloadModel();
              this.toast.success('Aplicación rechazada');
            });
          }
        });
      },

      desistirAplicacion(aplicacion) {
        let controller = this;

        if (controller.changeset.get('estaDeshabilitado')) {
          this.toast.error(this.messages.REQUERIMIENTO_DESHABILITADO);
          return;
        } else if (aplicacion.esRechazada) {
          this.toast.error(this.messages.APLICACION_RECHAZADA);
          return;
        } else if (aplicacion.esDesistida) {
          this.toast.error(this.messages.APLICACION_DESISTIDA);
          return;
        } else if (aplicacion.estatus == this.constants.APPLICATION_STATUS_CONFIRMADO_PENDIENTE) {
          this.toast.error(this.messages.APLICACION_CONFIRMADA_PENDIENTE_INGRESO);
          return;
        } else if (aplicacion.get('terna.content')) {
          this.toast.error(this.messages.APLICACION_EN_TERNA);
          return;
        }

        if (aplicacion.get('estatus') !== this.constants.APPLICATION_STATUS_INVESTIGACION) {
          this.toast.error('El candidato ya no se encuentra en esta fase');
          return;
        }

        this.send('openModal', 'popups/desistir-aplicacion', {
          title: 'Desistir aplicación',
          tipo: 'APLICACION',
          fase: 'INVESTIGACION',
          razonCancelacion: null,
          razonListaNegra: null,
          buttonActions: [{
            title: 'Desistir aplicación',
            methodName: 'desistirAplicacion',
            type: 'warning'
          }],
          desistirAplicacion: (observaciones, razonCancelacion) => {
            if (!observaciones || !razonCancelacion) {
              this.toast.error('Campo de razón requerido');
              return;
            }

            aplicacion.set('estatus', this.constants.APPLICATION_STATUS_DESISTIO_INVESTIGACION);
            aplicacion.set('observacionesDesistimiento', observaciones);
            aplicacion.set('razonDesistimiento', razonCancelacion.get('nombre'));
            aplicacion.save().then(() => {
              this.reloadModel();
              this.toast.success('Aplicación desistida');
            });
          }
        });
      },

      marcarFaseInvestigacion() {
        this.changeset.validate().then(() => {
          if (this.changeset.get('isInvalid')) {
            this.toast.error('Corrija los errores del formulario antes de realizar esta acción.');
            return;
          }

          this.changeset.set('fase', 'INVESTIGACION');
          this.changeset.set('fechaFase', new Date());
          this.parentCtrl.submit();
        });
      },

      revivirAplicacion(aplicacion) {
        let controller = this;

        if (controller.changeset.get('estaDeshabilitado')) {
          this.toast.error(this.messages.REQUERIMIENTO_DESHABILITADO);
          return;
        } else if (!aplicacion.esRechazada) {
          this.toast.error(this.messages.APLICACION_RECHAZADA);
          return;
        }

        this.send('openModal', 'popups/simple-confirm', {
          message: '¿Está seguro de revivir la aplicación?',
          onSubmit: () => {
            aplicacion.set('estatus', this.constants.APPLICATION_STATUS_PRE_SCREENING);
            aplicacion.set('listaNegra', false);
            aplicacion.set('razonListaNegra', null);
            aplicacion.save().then(() => {
              this.reloadModel();
              this.toast.success('Aplicación revivida');
            });
          }
        });
      }

    }
  });

  _exports.default = _default;
});