define("ods-app/models/bitacora-evento", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    fecha: attr('custom-date', {
      defaultValue() {
        return new Date();
      }

    }),
    observaciones: attr('string'),
    tipoEvento: belongsTo('bitacora-tipo-evento'),
    requerimiento: belongsTo('requerimiento'),
    fechaFormatted: Ember.computed('fecha', function () {
      const momentDate = (0, _moment.default)(this.fecha);

      if (Ember.isNone(this.fecha) || !momentDate.isValid()) {
        return '';
      }

      return momentDate.format('DD/MM/YYYY HH:mm:ss A');
    }),
    createdBy: attr('string')
  });

  _exports.default = _default;
});