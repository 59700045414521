define("ods-app/models/reporte-referidos", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    startDate: attr('custom-date'),
    endDate: attr('custom-date'),
    soloContratadas: attr('boolean', {
      defaultValue: false
    })
  });

  _exports.default = _default;
});