define("ods-app/routes/cliente/record/datos-generales/factorh-empresa", ["exports", "ods-app/mixins/permission-validator"], function (_exports, _permissionValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissionValidator.default, {
    ajaxService: Ember.inject.service(),
    loader: Ember.inject.service(),
    routePermission: 'EDIT_CLIENT_FACTORH_COMPANY',
    indexRoute: 'cliente.record.datos-generales',

    model() {
      this.loader.setIsLoading();
      return Ember.RSVP.hash({
        factorhEmpresas: this.ajaxService.request('/factorh-empresas').then(empresasRes => empresasRes.data)
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      let changeset = this.controllerFor(this.indexRoute).get('changeset');
      let factorhEmpresa = changeset.get('factorhEmpresa');
      changeset.set('factorhEmpresaProxy', null);

      if (factorhEmpresa) {
        let factorhEmpresaObj = model.factorhEmpresas.findBy('abreviatura', factorhEmpresa);

        if (factorhEmpresaObj) {
          changeset.set('factorhEmpresaProxy', factorhEmpresaObj);
        } else {
          this.toast.error(`La empresa ${factorhEmpresa} ya no existe en FactoRH.`);
        }
      }

      let factorhEmpresasProxy = model.factorhEmpresas.filter(factorhEmpresaObj => changeset.get('factorhEmpresas').includes(factorhEmpresaObj.abreviatura));
      changeset.set('factorhEmpresasProxy', factorhEmpresasProxy); // Alertar sobre empresas que ya no existen en FactoRH.

      changeset.get('factorhEmpresas').forEach(factorhEmpresa => {
        let factorhEmpresaObj = factorhEmpresasProxy.findBy('abreviatura', factorhEmpresa);

        if (!factorhEmpresaObj) {
          this.toast.error(`La empresa ${factorhEmpresa} ya no existe en FactoRH.`);
        }
      });
      controller.setProperties({
        changeset,
        record: this.modelFor(this.indexRoute).record
      });
    }

  });

  _exports.default = _default;
});