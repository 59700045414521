define("ods-app/templates/components/tables/cell/record-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L5Np/Wrf",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[26,\"and\",[[21,0,[\"route\"]],[21,0,[\"recordId\"]]],null]],null,{\"statements\":[[4,\"link-to\",[[21,0,[\"route\"]],[21,0,[\"recordId\"]]],[[\"title\",\"target\"],[[26,\"or\",[[22,[\"linkTitle\"]],\"Abrir\"],null],\"_blank\"]],{\"statements\":[[0,\"    \"],[1,[21,0,[\"value\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[21,0,[\"value\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/components/tables/cell/record-link.hbs"
    }
  });

  _exports.default = _default;
});