define("ods-app/templates/requerimiento/record/informacion-compensacion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c4oy5bUW",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[26,\"is-empresa\",[\"SERVINOVA\"],null]],null,{\"statements\":[[0,\"  \"],[14,\"requerimiento/record/informacion-compensacion-servinova\",[]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[26,\"is-empresa\",[\"OPTIMUM\"],null]],null,{\"statements\":[[0,\"  \"],[14,\"requerimiento/record/informacion-compensacion-optimum\",[]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[26,\"is-empresa\",[\"EL_TAIER\"],null]],null,{\"statements\":[[0,\"  \"],[14,\"requerimiento/record/informacion-compensacion-eltaier\",[]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[14,\"requerimiento/record/informacion-compensacion-default\",[]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "ods-app/templates/requerimiento/record/informacion-compensacion.hbs"
    }
  });

  _exports.default = _default;
});