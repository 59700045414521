define("ods-app/routes/requerimiento/record/informacion/factorh-puesto", ["exports", "ods-app/mixins/permission-validator"], function (_exports, _permissionValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissionValidator.default, {
    ajaxService: Ember.inject.service(),
    toast: Ember.inject.service(),
    loader: Ember.inject.service(),
    routePermission: 'EDIT_REQ_FACTORH_PUESTO',
    indexRoute: 'requerimiento.record.informacion',

    model() {
      let requerimiento = this.modelFor('requerimiento.record').record;

      if (requerimiento.get('noEsEditablePorUsuario')) {
        this.toast.error('Ya no se puede modificar el requerimiento.');
        return this.transitionTo(this.indexRoute);
      }

      this.loader.setIsLoading();
      return this.ajaxService.request('/factorh-puestos', {
        data: {
          requerimientoId: requerimiento.id
        }
      }).then(({
        data: factorhPuestos
      }) => ({
        factorhPuestos
      })).catch(error => {
        this.toast.error('Ocurrió un error al obtener la lista de puestos.');
        this.transitionTo(this.indexRoute);
        throw error;
      }).finally(() => this.loader.setNotLoading());
    },

    setupController(controller, model) {
      this._super(controller, model);

      let changeset = this.controllerFor(this.indexRoute).get('changeset');
      changeset.set('factorhPuestoProxy', null);

      if (changeset.get('factorhPuesto')) {
        const factorhPuestoObj = model.factorhPuestos.findBy('abreviatura', changeset.get('factorhPuesto'));

        if (factorhPuestoObj) {
          changeset.set('factorhPuestoProxy', factorhPuestoObj);
        } else {
          this.toast.error('El puesto actual asignado ya no existe en FactoRH.');
        }
      }

      controller.setProperties({
        changeset,
        record: this.modelFor(this.indexRoute).record
      });
    }

  });

  _exports.default = _default;
});