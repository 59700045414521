define("ods-app/routes/aplicacion/record/historial/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    routeRecordIdentifierDynamicSegment: 'historial_id',
    modelName: 'aplicacion-historial',
    indexRoute: 'aplicacion.record.historial',
    validations: Ember.computed(function () {
      return {
        estatus: (0, _validators.validatePresence)({
          presence: true
        }),
        aplicacion: (0, _validators.validatePresence)({
          presence: true
        }),
        tipo: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    createRecordInstance() {
      const aplicacion = this.modelFor('aplicacion.record').record;
      const modelName = this.get('modelName');
      return this.store.createRecord(modelName, {
        aplicacion
      });
    },

    actions: {
      willTransition() {
        return true;
      }

    }
  });

  _exports.default = _default;
});