define("ods-app/controllers/aplicacion/record/historial/record", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    indexCtrl: Ember.inject.controller('aplicacion.record.historial'),
    indexRoute: 'aplicacion.record.historial',
    postSaveTransitionRoute: Ember.computed.readOnly('indexRoute'),
    availableTipo: null,

    postSave() {
      this.indexCtrl.reloadModel();
    },

    actions: {
      discardChanges() {
        if (this.changeset.get('isDirty')) {
          this.record.rollbackAttributes();
        }

        this.transitionToRoute(this.get('indexRoute'));
      },

      updateEstatus(changeset, propertyName, selectedValue = null) {
        changeset.set(propertyName, selectedValue);

        if (selectedValue === null) {
          this.set('availableTipo', null);
          return;
        }

        let availableTipo = this.store.query('requerimiento-tipo-estatus', {
          filter: {
            inFase: selectedValue
          }
        });
        this.set('availableTipo', availableTipo);
      }

    }
  });

  _exports.default = _default;
});