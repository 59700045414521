define("ods-app/models/proyecto-candidato", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    proyecto: belongsTo('proyecto'),
    candidato: belongsTo('candidato'),
    requerimiento: belongsTo('requerimiento'),
    proyectoCandidatosPlanillaCargos: hasMany('proyecto-candidato-planilla-cargo'),
    fechaAlta: attr('custom-date'),
    fechaBaja: attr('custom-date'),
    factorhEmpresa: attr('string'),
    factorhTipoPlanilla: attr('string'),
    factorhTipoPlanillaStr: attr('string'),
    factorhDepartamento: attr('string'),
    factorhDepartamentoStr: attr('string'),
    factorhPuesto: attr('string'),
    factorhPuestoStr: attr('string'),
    numeroSeguroSocial: attr('string'),
    activo: attr('boolean'),
    factorhContratacionId: attr('number'),
    fechaAltaFormatted: Ember.computed('fechaAlta', function () {
      const momentDate = (0, _moment.default)(this.fechaAlta);

      if (Ember.isNone(this.fechaAlta) || !momentDate.isValid()) {
        return '';
      }

      return momentDate.format('DD/MM/YYYY');
    }),
    fechaBajaFormatted: Ember.computed('fechaBaja', function () {
      const momentDate = (0, _moment.default)(this.fechaBaja);

      if (Ember.isNone(this.fechaBaja) || !momentDate.isValid()) {
        return '';
      }

      return momentDate.format('DD/MM/YYYY');
    }),
    activoFormatted: Ember.computed('activo', function () {
      return this.activo ? 'Sí' : 'No';
    })
  });

  _exports.default = _default;
});