define("ods-app/controllers/dashboard", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/models/requerimiento", "ods-app/helpers/is-empresa"], function (_exports, _abstractModuleIndex, _requerimiento, _isEmpresa) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    user: Ember.computed.alias('currentUser.user'),
    currentUser: Ember.inject.service(),
    requerimientosModelName: 'requerimiento',
    requerimientoModelClassObject: _requerimiento.default,
    editRoute: 'requerimiento.record',
    includedRelationships: Ember.computed(function () {
      return Ember.A(['ternas']);
    }),
    requerimientoGlobalFilters: Ember.computed('user', function () {
      let userId = this.user.get('id');
      let userProyectosIds = this.user.get('proyectos').map(p => p.id);

      if (this.user.get('permissions.VIEW_ALL_REQUIREMENTS_DASHBOARD')) {
        userId = null;
      }

      let filters = [{
        selectedFilter: {
          filterName: 'reclutador'
        },
        value: userId
      }, {
        selectedFilter: {
          filterName: 'proyecto'
        },
        value: userProyectosIds
      }];

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM')) {
        filters.push({
          selectedFilter: {
            filterName: 'estatus'
          },
          value: ['VACANTE', 'CONFIRMADO_PENDIENTE_DE_INGRESO', 'CONGELADO']
        });
      } else if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'EL_TAIER') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'SERVINOVA')) {
        filters.push({
          selectedFilter: {
            filterName: 'estatus'
          },
          value: ['EN_REVISION', 'PENDIENTE_DE_AUTORIZAR', 'VACANTE']
        });
      }

      return filters;
    }),
    requerimientoTernaFilters: Ember.computed('user', function () {
      let userId = this.user.get('id');
      let userProyectosIds = this.user.get('proyectos').map(p => p.id);

      if (this.user.get('permissions.VIEW_ALL_REQUIREMENTS_DASHBOARD')) {
        userId = null;
      }

      let filters = [{
        selectedFilter: {
          filterName: 'reclutador'
        },
        value: userId
      }, {
        selectedFilter: {
          filterName: 'proyecto'
        },
        value: userProyectosIds
      }];

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'EL_TAIER') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'SERVINOVA')) {
        filters.push({
          selectedFilter: {
            filterName: 'estatus'
          },
          value: 'VACANTE'
        });
      }

      return filters;
    }),
    requerimientoSeguimientoTabData: Ember.computed('requerimientos', function () {
      let tabData = Ember.A([]);
      let addedClients = [];
      this.get('requerimientosEnSeguimiento').map(req => req.get('proyecto.cliente')).forEach(client => {
        if (addedClients.includes(client.get('id'))) {
          return;
        }

        let requerimientosFilters = [...this.get('requerimientoGlobalFilters')];
        requerimientosFilters.pushObject({
          selectedFilter: {
            filterName: 'proyecto.cliente'
          },
          value: client.get('id')
        }); // Filtro para mostrar los requerimientos que estén en seguimiento, es decir
        // aquellos cuya primera terna no tenga todavía fecha de envío real

        requerimientosFilters.pushObject({
          selectedFilter: {
            filterName: 'custom.enSeguimiento'
          },
          value: 1
        }); // Filtro para mostrar los requerimientos que no sean reproceso

        requerimientosFilters.pushObject({
          selectedFilter: {
            filterName: 'esReprocesoDe'
          },
          value: {
            exists: 0
          }
        });

        if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM')) {
          tabData.pushObject({
            title: client.get('nombre'),
            filters: requerimientosFilters,
            data: Ember.A([]),
            client
          });
        } else if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'EL_TAIER') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'SERVINOVA')) {
          tabData.pushObject({
            title: client.get('nombreComercial'),
            filters: requerimientosFilters,
            data: Ember.A([]),
            client
          });
        }

        addedClients.push(client.get('id'));
      });
      return tabData;
    }),
    requerimientoTernaTabData: Ember.computed('requerimientos', function () {
      let tabData = Ember.A([]);
      let addedClients = [];
      this.get('requerimientoEnTerna').map(req => req.get('proyecto.cliente')).forEach(client => {
        if (addedClients.includes(client.get('id'))) {
          return;
        }

        let requerimientosFilters = [...this.get('requerimientoGlobalFilters')];

        if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'EL_TAIER') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'SERVINOVA')) {
          requerimientosFilters = [...this.get('requerimientoTernaFilters')];
        }

        requerimientosFilters.pushObject({
          selectedFilter: {
            filterName: 'proyecto.cliente'
          },
          value: client.get('id')
        }); // Filtro para mostrar los requerimientos que estén en terna, es decir
        // aquellos cuya primera terna ya tenga fecha de envío real

        requerimientosFilters.pushObject({
          selectedFilter: {
            filterName: 'custom.enSeguimiento'
          },
          value: 0
        }); // Filtro para mostrar los requerimientos que no sean reproceso

        requerimientosFilters.pushObject({
          selectedFilter: {
            filterName: 'esReprocesoDe'
          },
          value: {
            exists: 0
          }
        });

        if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM')) {
          tabData.pushObject({
            title: client.get('nombre'),
            filters: requerimientosFilters,
            data: Ember.A([]),
            client
          });
        } else if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'EL_TAIER') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'SERVINOVA')) {
          tabData.pushObject({
            title: client.get('nombreComercial'),
            filters: requerimientosFilters,
            data: Ember.A([]),
            client
          });
        }

        addedClients.push(client.get('id'));
      });
      return tabData;
    }),
    requerimientoReprocesoTabData: Ember.computed('requerimientos', function () {
      let tabData = Ember.A([]);
      let addedClients = [];
      this.get('requerimientosDeReproceso').map(req => req.get('proyecto.cliente')).forEach(client => {
        if (addedClients.includes(client.get('id'))) {
          return;
        }

        let requerimientosFilters = [...this.get('requerimientoGlobalFilters')];
        requerimientosFilters.pushObject({
          selectedFilter: {
            filterName: 'proyecto.cliente'
          },
          value: client.get('id')
        }); // Filtro para mostrar los requerimientos que sean reproceso

        requerimientosFilters.pushObject({
          selectedFilter: {
            filterName: 'esReprocesoDe'
          },
          value: {
            exists: 1
          }
        });

        if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM')) {
          tabData.pushObject({
            title: client.get('nombre'),
            filters: requerimientosFilters,
            data: Ember.A([]),
            client
          });
        } else if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'EL_TAIER') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'SERVINOVA')) {
          tabData.pushObject({
            title: client.get('nombreComercial'),
            filters: requerimientosFilters,
            data: Ember.A([]),
            client
          });
        }

        addedClients.push(client.get('id'));
      });
      return tabData;
    }),
    columnsSeguimiento: Ember.computed(function () {
      return Ember.A([{
        label: 'Código',
        valuePath: 'codigo',
        sortable: false,
        width: '90px',
        filtrable: true,
        filterName: 'codigo'
      }, {
        label: 'Estatus',
        valuePath: 'statusToString',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'estatus',
        filterInputType: 'select',
        filterLabelPath: 'name',
        filterTargetPath: 'key',
        filterOptions: (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM') ? this.constants.REQUIREMENT_STATUS : this.constants.REQUIREMENT_STATUS_EL_TAIER
      }, {
        label: 'Proyecto',
        valuePath: (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM') ? 'proyecto.codigo' : 'proyecto.nombreProyectoElTaier',
        sortable: false,
        width: '180px',
        filtrable: true,
        filterName: (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM') ? 'proyecto.codigo' : 'proyecto.factorhDepartamentoStr'
      }, {
        label: 'Nombre de la plaza',
        valuePath: 'nombrePlaza',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'nombrePlaza'
      }, {
        label: 'Fecha de solicitud',
        valuePath: 'fechaSolicitudFormatted',
        sortable: false,
        width: '100px'
      }, {
        label: 'Fecha de seguimiento',
        valuePath: 'fechaSeguimientoProgramadaFormatted',
        sortable: false,
        width: '100px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '100px',
        objectActions: {
          edit: {
            title: 'Ver requerimiento',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          goAplicacion: {
            title: 'Ver aplicaciones',
            buttonClass: 'btn-warning',
            icon: 'tasks',
            action: this.actions.goAplicacion.bind(this)
          }
        }
      }]);
    }),
    columnsTerna: Ember.computed(function () {
      return Ember.A([{
        label: 'Código',
        valuePath: 'codigo',
        sortable: false,
        width: '90px',
        filtrable: true,
        filterName: 'codigo'
      }, {
        label: 'Situación',
        valuePath: 'ternaActiva.situacion',
        sortable: false,
        width: '130px'
      }, {
        label: 'Estatus',
        valuePath: 'statusToString',
        sortable: true,
        width: '100px',
        filtrable: (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM') ? true : false,
        filterName: 'estatus',
        filterInputType: 'select',
        filterLabelPath: 'name',
        filterTargetPath: 'key',
        filterOptions: (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM') ? this.constants.REQUIREMENT_STATUS : ''
      }, {
        label: 'Proyecto',
        valuePath: (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM') ? 'proyecto.codigo' : 'proyecto.nombreProyectoElTaier',
        sortable: false,
        width: '150px',
        filtrable: true,
        filterName: (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM') ? 'proyecto.codigo' : 'proyecto.factorhDepartamentoStr'
      }, {
        label: 'Nombre de la plaza',
        valuePath: 'nombrePlaza',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'nombrePlaza'
      }, {
        label: 'Fecha de seguimiento',
        valuePath: 'fechaSeguimientoProgramadaFormatted',
        sortable: false,
        width: '100'
      }, {
        label: 'Fecha de vencimiento',
        valuePath: 'fechaSolicitudFormatted',
        sortable: false,
        width: '100'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '100px',
        objectActions: {
          edit: {
            title: 'Ver requerimiento',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          goTerna: {
            title: 'Ver terna',
            buttonClass: 'btn-warning',
            icon: 'tasks',
            action: this.actions.goTerna.bind(this)
          }
        }
      }]);
    }),
    columnsReproceso: Ember.computed(function () {
      return Ember.A([{
        label: 'Código',
        valuePath: 'codigo',
        sortable: false,
        width: '90px',
        filtrable: true,
        filterName: 'codigo'
      }, {
        label: 'Situación',
        valuePath: 'ternaActiva.situacion',
        sortable: false,
        width: '130px'
      }, {
        label: 'Estatus',
        valuePath: 'statusToString',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'estatus',
        filterInputType: 'select',
        filterLabelPath: 'name',
        filterTargetPath: 'key',
        filterOptions: (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM') ? this.constants.REQUIREMENT_STATUS : this.constants.REQUIREMENT_STATUS_EL_TAIER
      }, {
        label: 'Proyecto',
        valuePath: 'proyecto.codigo',
        sortable: false,
        width: '150px',
        filtrable: true,
        filterName: 'proyecto.codigo'
      }, {
        label: 'Nombre de la plaza',
        valuePath: 'nombrePlaza',
        sortable: false,
        width: '160px',
        filtrable: true,
        filterName: 'nombrePlaza'
      }, {
        label: 'Fecha de seguimiento',
        valuePath: 'fechaSeguimientoProgramadaFormatted',
        sortable: false,
        width: '150px'
      }, {
        label: 'Fecha de vencimiento',
        valuePath: 'fechaSolicitudFormatted',
        sortable: false,
        width: '150px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '100px',
        objectActions: {
          edit: {
            title: 'Ver requerimiento',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          goReproceso: {
            title: 'Ver reproceso',
            buttonClass: 'btn-warning',
            icon: 'tasks',
            action: this.actions.goReproceso.bind(this)
          }
        }
      }]);
    }),
    actions: {
      goAplicacion(req) {
        this.transitionToRoute('requerimiento.record.aplicacion', req.get('id'));
      },

      goTerna(req) {
        this.transitionToRoute('requerimiento.record.terna', req.get('id'));
      },

      goReproceso(req) {
        this.transitionToRoute('requerimiento.record', req.get('id'));
      }

    }
  });

  _exports.default = _default;
});