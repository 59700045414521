define("ods-app/controllers/requerimiento/record/ingreso/asociar-proyecto", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    toast: Ember.inject.service(),
    loader: Ember.inject.service(),
    proyecto: '',
    actions: {
      discardChanges() {
        this.transitionToRoute('requerimiento.record.ingreso', this.requerimiento.get('id'));
      },

      asociarAPlanilla() {
        if (!this.proyecto) {
          this.toast.error('Debe seleccionar un proyecto.');
          return;
        }

        if (!this.proyecto.get('factorhDepartamento')) {
          this.toast.error('El proyecto debe tener asociado un departamento de FactoRH.');
          return;
        }

        let proyectoCandidato = this.store.createRecord('proyecto-candidato', {
          proyecto: this.proyecto,
          requerimiento: this.requerimiento,
          candidato: this.aplicacion.get('candidato'),
          fechaAlta: this.aplicacion.get('fechaIngreso'),
          activo: true
        });
        this.loader.setIsLoading();
        proyectoCandidato.save().then(() => {
          this.aplicacion.set('estatus', this.constants.APPLICATION_STATUS_CONTRATADO);
          this.aplicacion.save();
          this.toast.success('Registro creado correctamente.');
          this.transitionToRoute('requerimiento.record.ingreso', this.requerimiento.get('id'));
        }).catch(error => {
          var errorMessage = 'No se pudo asociar el candidato al proyecto';

          if (!Ember.isNone(error.errors)) {
            errorMessage += JSON.stringify(error.errors);
          }

          this.toast.error(errorMessage);
          throw error;
        }).finally(() => this.loader.setNotLoading());
      }

    }
  });

  _exports.default = _default;
});