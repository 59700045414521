define("ods-app/models/requerimiento-tipo-estatus", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    nombre: attr('string'),
    fases: attr(),
    activo: attr('boolean', {
      defaultValue: true
    }),
    fasesMapped: Ember.computed('fases', function () {
      let fases = this.get('fases') || [];
      return this.constants.DEFAULT_PHASES.filter(f => fases.includes(f.key));
    }),
    fasesMappedString: Ember.computed('fasesMapped', function () {
      return this.fasesMapped.map(f => f.name).join(', ');
    }),
    activoFormatted: Ember.computed('activo', function () {
      return this.activo ? 'Sí' : 'No';
    })
  });

  _exports.default = _default;
});