define("ods-app/routes/candidatos-existentes/index", ["exports", "servir-ember-utilities/routes/abstract-module-index"], function (_exports, _abstractModuleIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    breadcrumbsRoute: Ember.computed(function () {
      return [{
        name: 'Candidatos existentes',
        route: 'candidatos-existentes'
      }];
    }),

    model() {
      return {
        sinAplicaciones: Ember.A([]),
        conAplicaciones: Ember.A([])
      };
    },

    setupController() {
      this._super(...arguments);

      this.controller.set('reloadSinAplicaciones', true);
      this.controller.set('reloadConAplicaciones', true);
    }

  });

  _exports.default = _default;
});