define("ods-app/utils/app-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    isTruthhy(value) {
      return value === true || value === 1 || value === 'true' || value === '1' || value === 'yes';
    },

    getPromiseContent(objectPromise, propertyName = null) {
      if (typeof objectPromise !== 'object') {
        return objectPromise;
      }

      let result = propertyName ? objectPromise.get(propertyName) : objectPromise;

      if (result && result.isFulfilled !== undefined) {
        result = result.content;
      }

      return result;
    }

  };
  _exports.default = _default;
});