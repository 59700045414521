define("ods-app/templates/requerimiento/record/prueba", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4AZSIwck",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"pull-right\"],[8],[0,\"\\n\"],[4,\"if\",[[26,\"user-can\",[\"SET_REQUIREMENT_PHASE\"],null]],null,{\"statements\":[[4,\"bs-button\",null,[[\"size\",\"type\",\"disabled\",\"onClick\"],[\"xs\",\"info\",[26,\"or\",[[26,\"eq\",[[22,[\"record\",\"fase\"]],\"PRUEBAS_PSICOMETRICAS\"],null],[22,[\"changeset\",\"noEsEditablePorUsuario\"]]],null],[26,\"action\",[[21,0,[]],\"marcarFasePruebasPsicometricas\"],null]]],{\"statements\":[[0,\"      \"],[6,\"i\"],[10,\"class\",\"fa fa-check\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n      Marcar fase \\\"Pruebas psicométricas\\\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[1,[26,\"tables/paginated-table\",null,[[\"columns\",\"modelName\",\"modelClassObject\",\"modelFilters\",\"modelSortingProperties\",\"load\"],[[22,[\"columns\"]],\"aplicacion\",[22,[\"modelClass\"]],[22,[\"modelFilters\"]],[22,[\"modelSortingProperties\"]],[22,[\"reload\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/requerimiento/record/prueba.hbs"
    }
  });

  _exports.default = _default;
});