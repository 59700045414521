define("ods-app/models/aplicacion", ["exports", "ember-data", "moment", "accounting/format-number"], function (_exports, _emberData, _moment, _formatNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    init() {
      this._super(...arguments);

      this.set('changeTracker', {
        only: ['terna']
      });
    },

    candidato: belongsTo('candidato'),
    requerimiento: belongsTo('requerimiento'),
    terna: belongsTo('terna'),
    codigo: attr('string'),
    createdAt: attr('custom-date', {
      defaultValue() {
        return new Date();
      }

    }),
    expectativaSalarial: attr('number', {
      defaultValue: 0
    }),
    fuenteReclutamiento: belongsTo('fuente-reclutamiento'),
    nombreReferido: attr('string'),
    estatus: attr('string'),
    experiencia: attr('string'),
    observaciones: attr('string'),
    diasActivos: attr('number'),
    archivos: hasMany('aplicacion-archivo'),
    comentarios: attr('string'),
    observacionesEntrevista: attr('string'),
    observacionesRechazo: attr('string'),
    entrevistas: hasMany('entrevista'),
    llamadas: hasMany('llamada'),
    seleccionado: attr('boolean'),
    fechaIngreso: attr('custom-date'),
    fechaFinLabores: attr('custom-date'),
    moneda: belongsTo('moneda'),
    notificado: attr('boolean'),
    observacionesDesistimiento: attr('string'),
    razonRechazo: attr('string'),
    razonDesistimiento: attr('string'),
    createdAtFormatted: Ember.computed('createdAt', function () {
      const momentDate = (0, _moment.default)(this.createdAt);

      if (Ember.isNone(this.createdAt) || !momentDate.isValid()) {
        return '';
      }

      return momentDate.format('DD/MM/YYYY');
    }),
    statusToString: Ember.computed('estatus', function () {
      const status = this.constants.APPLICATION_STATUS.findBy('key', this.estatus);

      if (this.estatus === this.constants.APPLICATION_STATUS_EN_TERNA && this.get('terna.correlativo')) {
        return `En terna ${this.get('terna.correlativo')}`;
      }

      return status ? status.name : this.estatus;
    }),
    esRechazada: Ember.computed('estatus', function () {
      let esRechazada = false;

      if (this.estatus == this.constants.APPLICATION_STATUS_RECHAZADO || this.estatus == this.constants.APPLICATION_STATUS_RECHAZADO_EN_ENTREVISTA || this.estatus == this.constants.APPLICATION_STATUS_RECHAZADO_EN_PRUEBAS || this.estatus == this.constants.APPLICATION_STATUS_RECHAZADO_PRESENTACION || this.estatus == this.constants.APPLICATION_STATUS_RECHAZADO_PRUEBAS_TECNICAS) {
        esRechazada = true;
      }

      return esRechazada;
    }),
    esDesistida: Ember.computed('estatus', function () {
      let esDesistida = false;

      if (this.estatus == this.constants.APPLICATION_STATUS_DESISTIO || this.estatus == this.constants.APPLICATION_STATUS_DESISTIO_EN_ENTREVISTA || this.estatus == this.constants.APPLICATION_STATUS_DESISTIO_EN_PRUEBAS || this.estatus == this.constants.APPLICATION_STATUS_DESISTIO_PRESENTACION || this.estatus == this.constants.APPLICATION_STATUS_DESISTIO_PRUEBAS_TECNICAS) {
        esDesistida = true;
      }

      return esDesistida;
    }),
    expectativaSalarialFormatted: Ember.computed('expectativaSalarial', function () {
      return (0, _formatNumber.default)(this.get('expectativaSalarial'), 2);
    })
  });

  _exports.default = _default;
});