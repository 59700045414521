define("ods-app/components/tables/cell/record-link", ["exports", "ods-app/templates/components/tables/cell/record-link"], function (_exports, _recordLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _recordLink.default,
    row: null,
    column: null,
    table: null,
    value: null,
    recordId: null,
    route: Ember.computed.readOnly('column.route'),
    idRoute: Ember.computed.readOnly('column.idRoute'),
    linkTitle: Ember.computed.readOnly('column.linkTitle'),

    init() {
      this._super(...arguments);

      let item = this.get('row.content');
      this.set('recordId', item ? item.get(this.get('idRoute') || 'id') : null);
    }

  });

  _exports.default = _default;
});