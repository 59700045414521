define("ods-app/controllers/proyecto/record/servicio/factorh-departamento", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    indexRoute: 'proyecto.record.servicio',
    actions: {
      onChange(factorhDepartamento) {
        if (!factorhDepartamento) {
          this.changeset.set('factorhDepartamento', null);
          this.changeset.set('factorhDepartamentoStr', null);
          return;
        }

        this.changeset.set('factorhDepartamento', factorhDepartamento.abreviatura);
        this.changeset.set('factorhDepartamentoStr', factorhDepartamento.descripcion);
      },

      discardChanges() {
        this.changeset.rollback();
        this.record.rollbackAttributes();
        this.transitionToRoute(this.get('indexRoute'));
      }

    }
  });

  _exports.default = _default;
});