define("ods-app/initializers/messages", ["exports", "ods-app/models/messages"], function (_exports, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.register('service:messages', Ember.Object.extend(_messages.default));
    application.inject('route', 'messages', 'service:messages');
    application.inject('controller', 'messages', 'service:messages');
    application.inject('model', 'messages', 'service:messages');
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});