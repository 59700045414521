define("ods-app/routes/requerimiento/index", ["exports", "servir-ember-utilities/routes/abstract-module-index"], function (_exports, _abstractModuleIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    ajaxService: Ember.inject.service(),
    user: Ember.computed.alias('currentUser.user'),
    breadcrumbsRoute: Ember.computed(function () {
      return [{
        name: 'Requerimientos',
        route: 'requerimiento'
      }];
    }),

    model() {
      let userProyectosIds = this.user.get('proyectos').map(p => p.id);
      let queries = {
        requerimientos: this.ajaxService.request('/clientes-requerimientos', {
          data: {
            estatus: ['BORRADOR', 'VACANTE', 'CONGELADO', 'EN_REVISION', 'PENDIENTE_DE_AUTORIZAR'],
            proyectos: userProyectosIds
          }
        }),
        requerimientosPendienteIngreso: this.ajaxService.request('/clientes-requerimientos', {
          data: {
            estatus: ['CONFIRMADO_PENDIENTE_DE_INGRESO'],
            proyectos: userProyectosIds
          }
        })
      };
      return Ember.RSVP.hash(queries);
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.setProperties(model);
      controller.set('reload', true);
    }

  });

  _exports.default = _default;
});