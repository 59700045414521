define("ods-app/routes/cliente/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "servir-ember-utilities/mixins/route/has-tabs", "servir-ember-utilities/validators/custom-presence", "ember-changeset-validations/validators", "servir-ember-utilities/validators/is-codigo", "ods-app/mixins/permission-validator", "ods-app/helpers/is-empresa"], function (_exports, _abstractModuleRecord, _hasTabs, _customPresence, _validators, _isCodigo, _permissionValidator, _isEmpresa) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_hasTabs.default, _permissionValidator.default, {
    modelName: 'cliente',
    indexRoute: 'cliente',
    defaultTabSubRoute: 'datos-generales',
    routePermission: 'VIEW_CLIENTS',
    currentUser: Ember.inject.service(),
    breadcrumbsRoute: Ember.computed('recordId', function () {
      return [{
        name: 'Clientes',
        route: 'cliente'
      }, {
        name: 'Editar',
        route: `cliente.record.${this.defaultTabSubRoute}`,
        queryParam: this.recordId
      }];
    }),
    validations: Ember.computed(function () {
      let validaciones = {
        codigo: [(0, _customPresence.default)({
          presence: true
        }), (0, _isCodigo.default)()],
        nombre: (0, _validators.validatePresence)({
          presence: true
        }),
        nombreComercial: (0, _validators.validatePresence)({
          presence: true
        }),
        nit: (0, _validators.validatePresence)({
          presence: true
        }),
        telefono: (0, _validators.validatePresence)({
          presence: true
        }),
        direccion: (0, _validators.validatePresence)({
          presence: true
        }),
        pais: (0, _validators.validatePresence)({
          presence: true
        })
      };

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM')) {
        validaciones = Object.assign(validaciones, {
          fuenteReclutamiento: (0, _validators.validatePresence)({
            presence: true
          })
        });
      }

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'SERVINOVA') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'DEMO')) {
        validaciones = Object.assign(validaciones, {
          facturaNombre: (0, _validators.validatePresence)({
            presence: true
          }),
          facturaDireccion: (0, _validators.validatePresence)({
            presence: true
          }),
          serviciosPrestados: (0, _validators.validatePresence)({
            presence: true
          })
        });
      }

      return validaciones;
    }),
    relatedModelsToQuery: Ember.computed(function () {
      let relatedModels = [{
        name: 'fuentesReclutamiento',
        modelName: 'fuente-reclutamiento',
        queryParams: {
          filter: {
            tipo: this.constants.REQUIREMENT_SOURCE_TYPE_CLIENTE
          }
        }
      }, {
        name: 'paises',
        modelName: 'pais'
      }, {
        name: 'departamentos',
        modelName: 'departamento'
      }, {
        name: 'municipios',
        modelName: 'municipio'
      }, {
        name: 'monedas',
        modelName: 'moneda'
      }];
      return relatedModels;
    })
  });

  _exports.default = _default;
});