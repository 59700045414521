define("ods-app/controllers/aplicacion/record/historial", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/models/aplicacion-historial"], function (_exports, _abstractModuleIndex, _aplicacionHistorial) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    modelClass: _aplicacionHistorial.default,
    reload: true,
    // includedRelationships: computed(function() {
    //   return emberArray(['aplicacion']);
    // }),
    modelFilters: Ember.computed('record', function () {
      return [{
        selectedFilter: {
          filterName: 'aplicacion'
        },
        value: this.get('record.id') || -1
      }];
    }),
    columns: Ember.computed(function () {
      let columns = Ember.A([{
        label: 'Fecha',
        valuePath: 'createdAtFormatted',
        sortable: false,
        width: '100px'
      }, {
        label: 'Usuario',
        valuePath: 'createdBy',
        sortable: false,
        width: '100px'
      }, {
        label: 'Estatus',
        valuePath: 'estatusToString',
        sortable: false,
        width: '100px'
      }]);

      if (this.currentUser.isEmpresa('DEMO')) {
        columns.push({
          label: 'Tipo',
          valuePath: 'tipo.nombre',
          sortable: false,
          width: '100px'
        });
      }

      columns.push({
        label: 'Observaciones',
        valuePath: 'observaciones',
        sortable: false,
        width: '100px'
      });

      if (this.currentUser.isEmpresa('DEMO')) {
        columns.push({
          label: 'Acciones',
          cellComponent: 'tables/cell/object-actions',
          table: this,
          sortable: false,
          width: '100px',
          objectActions: {
            delete: {
              title: 'Eliminar',
              buttonClass: 'btn-danger',
              icon: 'trash-o',
              action: this.actions.delete.bind(this)
            }
          }
        });
      }

      return columns;
    }),

    reloadModel() {
      this.set('reload', true);
    }

  });

  _exports.default = _default;
});