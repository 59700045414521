define("ods-app/controllers/cliente/record/datos-contrato", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      onChangeTipoFee(changeset, propertyName, plaza = null) {
        changeset.set(propertyName, plaza);
      }

    }
  });

  _exports.default = _default;
});