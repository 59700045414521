define("ods-app/controllers/aplicacion/record/informacion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentDate: null,
    modelFilters: Ember.computed('record', function () {
      return [{
        selectedFilter: {
          filterName: 'fuentesReclutamiento'
        },
        value: this.get('record.id') || -1
      }];
    }),
    actions: {
      agregarListaNegra(candidatoProxy, proxy, propiedad, estado) {
        const candidato = candidatoProxy.content;
        candidato.set('listaNegra', estado);
        candidato.save();
      },

      searchCandidates(text) {
        if (!text || text.length < 3) {
          return;
        }

        if (this.contactsQuery) {
          Ember.run.cancel(this.contactsQuery);
        }

        this.contactsQuery = Ember.run.later(() => {
          this.set('availableCandidates', this.store.query('candidato', {
            filter: {
              primerNombre: {
                OR: text
              },
              primerApellido: {
                OR: text
              },
              segundoNombre: {
                OR: text
              },
              segundoApellido: {
                OR: text
              },
              uid: {
                OR: text
              }
            }
          }));
        }, 500);
      },

      onCandidatoChanged() {
        if (!this.changeset.get('candidato.id')) {
          return;
        }

        let candidato = this.changeset.get('candidato');
        this.changeset.set('expectativaSalarial', candidato.get('expectativaSalarial') || 0);
        this.changeset.set('fuenteReclutamiento', candidato.get('fuenteReclutamiento'));
      }

    }
  });

  _exports.default = _default;
});