define("ods-app/routes/bitacora-tipo-evento/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ods-app/mixins/permission-validator", "servir-ember-utilities/validators/custom-presence", "ember-changeset-validations/validators", "servir-ember-utilities/validators/is-codigo"], function (_exports, _abstractModuleRecord, _permissionValidator, _customPresence, _validators, _isCodigo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_permissionValidator.default, {
    routePermission: 'VIEW_BITACORA_TIPO_EVENTOS',
    modelName: 'bitacora-tipo-evento',
    indexRoute: 'bitacora-tipo-evento',
    validations: Ember.computed(function () {
      return {
        codigo: [(0, _customPresence.default)({
          presence: true
        }), (0, _isCodigo.default)()],
        nombre: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),
    actions: {
      willTransition() {
        return true;
      }

    }
  });

  _exports.default = _default;
});